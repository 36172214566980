import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  routeName: '',
  submitFailed: false,
  formError: false,
  deepNav: false,
  kultur: [Array, Object],
  navigation: [Array, Object],
  bbch: null,
  oldBBCH: null,
  bestandesdichteBundleId: null,
  bestandesdichte: [Array, Object]
}

export default new Vuex.Store({
  state,
  mutations: {
    changeRouteName (state, newName) {
      state.routeName = newName
    },
    submitFailed (state, submitState) {
      state.submitFailed = submitState
    },
    foundFormError (state, foundFormError) {
      state.formError = foundFormError
    },
    setDeepStateNavigation (state, deepNav) {
      state.deepNav = deepNav
    },
    setKultur (state, kultur) {
      state.kultur = kultur
    },
    setNavigation (state, navigation) {
      state.navigation = navigation
    },
    setBBCH (state, bbch) {
      state.bbch = bbch
    },
    setOldBBCH (state, oldBBCH) {
      state.oldBBCH = oldBBCH
    },
    bestandesdichte (state, bd) {
      state.bestandesdichte = bd
    },
    bestandesdichteBundleId (state, bd) {
      state.bestandesdichteBundleId = bd
    }
  },
  actions: {
  },
  modules: {
  }
})
