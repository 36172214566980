<template>
  <section>
    <el-table
      ref="infektionsRisikoTabelle"
      :cell-style="cellStyle"
      :data="pflanzenkrankheiten"
      :header-cell-style="tableHeaderColor"
      :row-style="tableRowStyle"
      v-loading="loading">
      <el-table-column
        prop="Anzeige_Name"
        label="Krankheiten"
        fixed
        width="110" style="color: #1e39bd">
      </el-table-column>
      <el-table-column
        v-for="(date, number) in infDateKeysSorted"
        :key="number"
        :prop="date"
        :label="date"
        width="50">
        <template v-slot="props">
          <div
            v-if="empfehlungsDetailRestschutz[props.row.Anzeige_Name]"
            class="restschutz-td"
            :style="{ height: empfehlungsDetailRestschutz[props.row.Anzeige_Name][date] * 100 + '%', opacity: empfehlungsDetailRestschutz[props.row.Anzeige_Name][date] - 0.25}">
          </div>
          <div class="cell-padding">
            <canvas :id="'pie_'+props.row.Anzeige_Name+'_'+date.replace('.', '_')" width="35" height="35"
                    style="display: block;"></canvas>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </section>
</template>

<script>
import moment from 'moment'
import { Chart } from 'chart.js'

export default {
  props: {
    empfehlungsDetailInfektionsrisiken: {
      type: Object,
      default: () => {
        return {}
      }
    },
    empfehlungsDetailRestschutz: {
      type: Object,
      default: () => {
        return {}
      }
    },
    aktuellesDatum: {
      type: String,
      default: ''
    },
    kultur: {
      type: [String, Number],
      default: 0
    },
    pflanzenkrankheit: {
      type: [String, Number],
      default: 0
    },
    pflanzenkrankheiten: {
      type: Array,
      default: Array
    },
    sorte: {
      type: [String, Number],
      default: 0
    }
  },
  data: function () {
    return {
      irCharts: [],
      infectionRiskData: [],
      infDateKeysSorted: [],
      infectionRiskDataSorted: [],
      loading: true
    }
  },
  created: function () {
    this.infectionRiskData = this.empfehlungsDetailInfektionsrisiken
    this.fillMissingDataInInfectionRiskTable()
    this.buildCharts()
  },
  updated () {
    this.$nextTick(() => {
      const table = this.$refs.infektionsRisikoTabelle
      this.$emit('updateTable', table)
    })
  },
  methods: {
    cellStyle ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      const wert = column.property
      if (wert === this.aktuellesDatum) {
        return {
          backgroundColor: '#606266de'
        }
      }
      // return { height: this.empfehlungsDetailRestschutz[pk.Anzeige_Name][idx]*100)+'% }
      // height:'+(; opacity: '+empfehlungsDetailRestschutz[pk.Anzeige_Name][idx]
    },
    tableRowStyle ({
      row,
      rowIndex
    }) {
      return {}
    },
    tableHeaderColor ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      const wert = column.property
      if (wert === this.aktuellesDatum) {
        return {
          // backgroundColor: '#606266de'
        }
      }
    },
    fillMissingDataInInfectionRiskTable () {
      const self = this
      const infektionsRisiken = JSON.parse(JSON.stringify(self.infectionRiskData))
      const infKeysSorted = []
      for (const item in infektionsRisiken) {
        infKeysSorted.push(item)
      }
      infKeysSorted.sort(function (a, b) {
        return Object.keys(infektionsRisiken[b]).length - Object.keys(infektionsRisiken[a]).length
      })
      const firstLineOfDates = infektionsRisiken[infKeysSorted[0]]
      Object.keys(firstLineOfDates).forEach(key => {
        Object.keys(infektionsRisiken).forEach(item => {
          if (!infektionsRisiken[item][key]) {
            infektionsRisiken[item][key] = {
              Pflanzenkrankheit: infektionsRisiken[item][Object.keys(infektionsRisiken[item])[0].Pflanzenkrankheit],
              Risiko: -1
            }
          }
        })
      })
      const dateKeysSorted = []
      Object.keys(firstLineOfDates).forEach(key => {
        dateKeysSorted.push(key)
      })
      dateKeysSorted.sort(function (a, b) {
        return moment(new Date(a)).isAfter(moment(new Date(b))) ? a : b
      })
      this.$set(self, 'infectionRiskData', infektionsRisiken)
      this.$set(self, 'infDateKeysSorted', dateKeysSorted)
      this.$set(self, 'infectionRiskDataSorted', infektionsRisiken)
    },
    removeDataFromChart (chart) {
      if (chart) {
        chart.destroy()
      }
    },
    buildCharts () {
      this.$nextTick(function () {
        const self = this
        const infektionsRisikenRaw = self.infectionRiskData
        const pflanzenkrankheiten = self.pflanzenkrankheiten
        const objectLength = Object.keys(infektionsRisikenRaw).length
        const infKeysSorted = []
        const infValuesSorted = []
        if ((objectLength === pflanzenkrankheiten.length)) {
          pflanzenkrankheiten.sort(function (a, b) {
            return a.Prio_In_Frontend - b.Prio_In_Frontend
          })
          for (const item in pflanzenkrankheiten) {
            infKeysSorted.push(pflanzenkrankheiten[item].Anzeige_Name)
          }

          infKeysSorted.forEach(item => {
            infValuesSorted.push(infektionsRisikenRaw[item])
          })

          infKeysSorted.sort(function (a, b) {
            return pflanzenkrankheiten.indexOf(a) - pflanzenkrankheiten.indexOf(b)
          })
          const infektionsRisiken = {}
          Object.keys(infektionsRisikenRaw).sort(function (a, b) {
            return infKeysSorted.indexOf(a) - infKeysSorted.indexOf(b)
          })
            .forEach(function (key) {
              infektionsRisiken[key] = infektionsRisikenRaw[key]
            })
          self.infectionRiskDataSorted = infektionsRisiken
          Object.keys(infektionsRisiken).forEach((item, itemKey) => {
            self.infDateKeysSorted.forEach((itm, itmKey) => {
              const selector = 'pie_' + item + '_' + itm.replace('.', '_')
              const element = document.getElementById(selector)
              if (selector) {
                const ctx = element.getContext('2d')
                let max = 1
                if (self.kultur === '6' && infektionsRisiken[item][itm].Pflanzenkrankheit === '3') {
                  max = 100
                }
                let gruenWert = 0
                let rotWert = 0
                let grauWert = 0
                if (infektionsRisiken[item][itm].Risiko !== -1) {
                  gruenWert = (max - infektionsRisiken[item][itm].Risiko) < 0 ? 0 : max - infektionsRisiken[item][itm].Risiko
                  rotWert = infektionsRisiken[item][itm].Risiko > max ? max : infektionsRisiken[item][itm].Risiko
                  grauWert = 0
                } else {
                  gruenWert = 0
                  rotWert = 0
                  grauWert = 1
                }
                this.irCharts[selector] = new Chart(ctx, {
                  type: 'pie',
                  data: {
                    datasets: [{
                      backgroundColor: [!this.phases ? '#FF3111' : infektionsRisiken[item][itm].Color, !this.phases ? '#59B20C' : infektionsRisiken[item][itm].Color],
                      data: [rotWert, gruenWert, grauWert],
                      borderWidth: 0
                    }]
                  },
                  options: {
                    legend: {
                      display: false
                    },
                    plugins: {
                      tooltip: {
                        enabled: false
                      },
                      responsive: false
                    }
                  },
                  plugins: [{
                    // beforeDraw: (chart) => {
                    //   const ctx = chart.canvas.getContext('2d')
                    //   ctx.save()
                    //   ctx.globalCompositeOperation = 'destination-over'
                    //   ctx.fillStyle = 'lightGreen'
                    //   ctx.fillRect(0, 0, chart.width, chart.height)
                    //   ctx.restore()
                    // }
                  }]
                })
              }
            })
            self.stopLoading(false)
          })
        }
        self.stopLoading(true)
      })
    },
    stopLoading (bool) {
      this.$refs.infektionsRisikoTabelle.doLayout()
      this.loading = !bool
    }
  }
}
</script>
<style>
.cell-padding {
  margin-top: -10px;
}

.restschutz-td {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: linear-gradient(135deg, #1e39bd, #1e39bd);
  transition: height 1s linear;
}

</style>
