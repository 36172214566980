import { encode } from 'querystring-es3'
const axios = require('axios')

export default {
  methods: {
    submitRueckmeldung (query) {
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + '/api/Rueckmeldung/addRueckmeldungFromPwa', query)
    }
  }
}
