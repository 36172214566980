<template>
  <section>
    <el-row v-for="(attr, key) in formAttributes" :key="key">
      <el-col
        v-if="attr[0].id === formFieldId"
        :span="6"
        :xs="24">
        <div class="grid-content">
          <el-card
            class="box-card">
            <div v-if="cdzForm.name !== 'Switch'"
                 slot="header" class="clearfix">
              <span><b>{{ cdzForm.name }}</b></span>
            </div>
            <component v-if="attr[0].id"
                       :cdzForm="cdzForm"
                       :id-kultur="idKultur"
                       :id-parzelle="idParzelle"
                       :id-parzelle-fruchtfolge="idParzelleFruchtfolge"
                       :base-bundle="baseBundle"
                       :form-base-bundle="formBaseBundle"
                       :form-tree="formTree"
                       :form-branch="formBranch"
                       :form-field-id="key"
                       :form-field-date="formFieldDate"
                       @setNewFormData="getFormData"
                       @newErrorState="newErrorState"
                       @bbchChanged="bbchChanged"
                       v-bind:is="attr[0].component"/>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import FormInputNumScale from './inputs/form-input-num-scale'
import FormSelectSingle from './selects/form-select-single'
import FormInputTextareaField from './inputs/form-input-textarea-field'
import FormInputDateField from './inputs/form-input-date-field'
import FormSelectDynamic from './selects/form-select-dynamic'
import FormSwitchField from './switchs/form-switch-field'

export default {
  name: 'form-component',
  components: {
    'form-scale-field': FormInputNumScale,
    'form-number-field': FormInputNumScale,
    'form-select-field': FormSelectSingle,
    'form-text-field': FormInputTextareaField,
    'form-date-field': FormInputDateField,
    'form-switch-field': FormSwitchField,
    FormSelectDynamic
  },
  props: {
    baseBundle: Array,
    formTree: String,
    formBranch: String,
    formField: {
      type: Object,
      default () {
        return {
          psmArray: [],
          pkArray: []
        }
      }
    },
    formFieldId: Number,
    formFieldDate: Object,
    validation: {
      type: Object
    },
    formAttributes: [Object, Array],
    formName: String,
    formBigBuild: Boolean,
    formBaseBundle: Array,
    formBaseTitle: String,
    formArray: Array
  },
  data () {
    return {
      component: 'form-select-dynamic',
      psmDate: {},
      mainFormAttributes: [],
      switchState: {
        formId: Number,
        value: false
      },
      show: false
    }
  },
  methods: {
    bbchChanged (data, bbch) {
      this.$store.commit('bestandesdichte', data)
      this.$emit('bbchChanged', data, bbch)
    },
    setPsmFormDate (value) {
      this.psmDate = value
    },
    newSwitchState (data) {
      this.switchState.formId = data.id
      this.switchState.value = data.value
      this.mainFormAttributes = data.mainAttributes
    }
  },
  watch: {
    cdzForm: {
      immediate: true,
      handler: function (data) {

      }
    },
    $props: {
      immediate: true,
      handler: function (data) {
        data = {
          kid: data.idKultur,
          pid: data.idParzelle,
          pfid: data.idParzelleFruchtfolge,
          ft: data.formTree,
          fb: data.formBranch
        }
        this.$emit('updateGlobalData', data)
      }
    },
    psmDate: {
      immediate: true,
      handler: function (date) {
        if (this.formField.psmArray) {
          if (date.value === '' || date.value === null) {
            this.$emit('deleteAllRows', {
              formTree: this.formTree,
              formBranch: this.formBranch
            })
          }
        }
      }
    }
  }
}
</script>

<style>
.error {
  color: red;
}
</style>
