<template>
  <section>
    <el-row v-for="(bundle) in baseBundleData" :key="bundle.bundleId">
      <div v-if="baseForm.id === bundle.formId">
        <el-form :model="bundle" :ref="bundle.name">
          <form-component
            :cdzForm="bundle"
            :form-base-bundle="baseBundleData"
            :base-bundle="baseBundleData"
            :form-base-title="baseForm.name"
            :form-name="bundle.formName"
            :form-field-id="bundle.rmaId"
            :form-attributes="baseEigenschaften"
            :form-array="baseArray"
            :form-big-build="bundle.firstOrder"
            :form-tree="baseForm.formTree"
            :form-branch="baseForm.formBranch"
            :id-kultur="baseForm.idKultur"
            :id-parzelle="baseForm.idParzelle"
            :id-parzelle-fruchtfolge="baseForm.idParzelleFruchtfolge"
            @updateGlobalData="updateGlobalData"
            @setFormData="getFormData"
            @newErrorState="newErrorState"
            @bbchChanged="bbchChanged">
          </form-component>
        </el-form>
      </div>
    </el-row>
  </section>
</template>

<script>

import FormComponent from '../forms/form-component'
import { formMethods } from '../../mixins/form-methods'

export default {
  name: 'kultur-rueckmeldung',
  components: { FormComponent },
  mixins: [formMethods],
  props: {
    baseForm: Object,
    baseBundle: Array,
    baseArray: Array,
    baseEigenschaften: Array
  },
  data () {
    return {
      baseBundleData: this.baseBundle,
      changed: false
    }
  },
  watch: {
    baseBundle: {
      immediate: true,
      handler: function (data) {
        this.baseBundleData = this.changed === false ? data : this.baseBundleData
      }
    },
    baseBundleData: {
      immediate: true,
      handler: function (data) {
        console.log('baseBundleData changed', data)
      }
    }
  },
  methods: {
    bbchChanged (data = null, currentEC = null) {
      this.changed = true
      const bundleId = this.$store.state.bestandesdichteBundleId
      if (bundleId && this.formDataErrors[bundleId]) {
        this.formDataErrors[bundleId].value = ''
        // const formName = 'Bestandesdichte'
        const options = this.$store.state.bestandesdichte.filter(opt => {
          const value = parseInt(opt.value)
          opt.disabled = false

          if (value === 0 && currentEC !== 9) {
            opt.disabled = true
            opt.selected = false
          } else if (value <= 2 && currentEC >= 61) {
            opt.disabled = true
            opt.selected = false
          } else if (currentEC <= 9 && value !== 0) {
            opt.disabled = true
            opt.selected = false
          } else if (currentEC >= 10 && currentEC <= 19 && value !== 1) {
            opt.disabled = true
            opt.selected = false
          } else if (currentEC >= 20 && currentEC <= 29 && value !== 2) {
            opt.disabled = true
            opt.selected = false
          } else if (currentEC === 30 && value !== 3) {
            opt.disabled = true
            opt.selected = false
          } else if (currentEC >= 31 && currentEC <= 38 && value !== 4) {
            opt.disabled = true
            opt.selected = false
          } else if (currentEC <= 60 && currentEC >= 39 && value <= 3) {
            opt.disabled = true
            opt.selected = false
          }

          return opt
        })

        this.baseBundleData = this.baseBundleData.filter(bbd => {
          bbd.options = bbd.bundleId === bundleId ? options : bbd.options
          return bbd
        })
        // this.$refs[formName][0].resetFields()
        this.$nextTick(function () {
          this.changed = false
        })
      }
    }
  }
}
</script>

<style scoped>
</style>
