import { encode } from 'querystring-es3'
const axios = require('axios')

export default {
  methods: {
    getUserParzellen (query) {
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/parzelle/getUserParzellen/pwaCallGetUserParzellen', query)
    },
    getRueckmeldungsDaten (query) {
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/rueckmeldungsart/getKulturRueckmeldungsArten/pwaCallGetRueckmeldungsDaten', query)
    },
    getAlleRueckmeldungsEigenschaften (query) {
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/rueckmeldungsart/getAlleRueckmeldungsEigenschaften/1', query)
    }
  }
}
