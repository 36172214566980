<template>
  <section>
    <el-input-number v-model="num"
                     :id="cdzForm.id"
                     :name="cdzForm.name"
                     :value="value"
                     :min="strToNum(cdzForm.options[0])"
                     :max="cdzForm.options[1]"
                     :precision="formFieldData.precision"
                     :step="formFieldData.step"
                     @change="submitChange">
    </el-input-number>
  </section>
</template>
<script>
export default {
  name: 'form-input-num-scale',
  props: {
    formField: {
      default () {
        return undefined
      }
    },
    formTree: String,
    formBranch: String,
    formFieldId: Number,
    formFieldDate: {
      default () {
        return undefined
      }
    },
    formFieldRows: {
      default () {
        return undefined
      }
    }
  },
  data () {
    return {
      num: !this.formField ? this.cdzForm.options[0] : this.formField.value,
      formFieldData: {
        range: {
          min: parseInt(this.cdzForm.min),
          max: parseInt(this.cdzForm.max),
          value: 0
        },
        precision: 0,
        step: 1,
        pId: 0,
        val: 0
      },
      psm: false,
      value: 0
    }
  },
  watch: {
    cdzForm: {
      immediate: true,
      handler: function (value) {
        if (!this.formField) {
          this.num = value.options[0]
          this.submitChange(value)
        }
      }
    },
    formField: {
      deep: true,
      handler: function (data) {
        if (this.formField) {
          this.formFieldData = data
          this.cdzForm.options[0] = data.range.min
          this.cdzForm.options[1] = data.range.max
          this.date = this.formFieldDate.value
          this.cdzForm.id = this.formFieldData.pId
          this.num = this.updateNum(data)
          if (this.formFieldData.pId) {
            this.submitChange(this.num)
          }
        }
      }
    },
    formFieldDate: {
      immediate: true,
      handler: function (data) {
        if (!this.formField) {
          if (data !== this.date) {
            this.submitChange(this.num)
          }
        }
      }
    }
  },
  methods: {
    updateNum (data) {
      const formData = JSON.parse(localStorage.formData)[this.idKultur][this.idParzelle][this.idParzelleFruchtfolge][this.formTree][this.formBranch]
      const index = formData.findIndex(item => item.pId === data.pId)
      let newNum = data.range.min
      for (let i = 0; i < this.formFieldRows.length; i++) {
        if (index === i) {
          newNum = formData[i].value
        }
      }
      return newNum
    },
    submitChange (value) {
      const id = this.cdzForm.id
      const rmaId = this.cdzForm.rmaId
      let key = this.cdzForm.bundleId
      key = key ?? this.formFieldId
      const date = this.date
      const formFieldDate = this.formFieldDate
      if (this.cdzForm.Wert !== '0-99' && !this.formField) {
        this.formFieldData.range.max = this.num + 1
      }
      this.$emit('setNewFormData', {
        id: id,
        pId: id,
        rmaId: rmaId,
        key: key,
        value: this.thisValIsObject(value) ? value.min : value,
        date: date ?? formFieldDate,
        formTree: this.formTree,
        formBranch: this.formBranch
      })
      this.$emit('cacheValue', {
        value: value,
        key: this.formFieldId
      })
    }
  }
}
</script>
