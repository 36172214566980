/* globals localStorage */
import user from './api/user'

export default {
  login (email, pass, cb) {
    cb = arguments[arguments.length - 1]
    if (localStorage.token) {
      // eslint-disable-next-line node/no-callback-literal
      if (cb) cb(true)
      this.onChange(true)
      return
    }
    user.methods.signIn(email, pass).then(response => {
      if (response.data.error === 0) {
        localStorage.token = response.data.token
        this.setUser(response.data.user)
        // eslint-disable-next-line node/no-callback-literal
        if (cb) cb(true)
        this.onChange(true)
      } else {
        // eslint-disable-next-line node/no-callback-literal
        if (cb) cb(false)
        this.onChange(false)
      }
    })
  },

  setUser (userObject) {
    localStorage.user = JSON.stringify(userObject)
    localStorage.unsavedFormData = JSON.stringify([])
  },

  getUser () {
    return JSON.parse(localStorage.user)
  },

  getToken () {
    return localStorage.token
  },

  logout (cb) {
    delete localStorage.token
    delete localStorage.user
    delete localStorage.kulturen
    if (cb) cb()
    this.onChange(false)
  },

  loggedIn () {
    let loggedIn = false
    loggedIn = !!this.getToken()
    return loggedIn
  },

  onChange () {
  }
}
