<template>
  <el-row type="flex" justify="center">
    <el-col :xs="Number(24)" :md="Number(12)" :lg="Number(12)" :xl="Number(6)">
      <el-card>
        <p v-if="$route.query.redirect">
          Sie müssen sich zuerst einloggen.
        </p>
        <form autocomplete="off">
          <el-divider>Login</el-divider>
          <el-row>
            <label>
              <el-input v-model="email" placeholder="E-Mail" v-focus></el-input>
            </label>
          </el-row>
          <el-row style="margin-top: 10px">
            <label>
              <el-input v-model="pass" placeholder="Passwort" type="password"></el-input>
            </label>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-col :span="24">
              <el-button class="pull-right" type="submit" @click="login">Login</el-button>
            </el-col>
          </el-row>
          <p v-if="error" class="error">Bad login information</p>
        </form>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import auth from '../auth'

export default {
  data () {
    return {
      email: '',
      pass: '',
      error: false
    }
  },
  methods: {
    login () {
      auth.login(this.email, this.pass, user => {
        if (!user) {
          this.error = true
        } else {
          this.$router.push('/')
        }
      })
    }
  }
}
</script>

<style>
.error {
  color: red;
}
</style>
