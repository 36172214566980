<template>
  <section>
    <el-col
      v-if="baseForm.formOrder.length > 0 && baseForm.formOrder[0][0].valid"
      :span="6"
      :xs="24">
      <div class="grid-content">
        <el-card>
          <component
            :cdz-form="setBundle(baseForm.formOrder[0][0].id, baseForm.formOrder[0][0].fieldId)"
            :form-main-attributes="baseBundle"
            :form-tree="baseForm.formTree"
            :form-branch="baseForm.formBranch"
            :id-kultur="baseForm.idKultur"
            :id-parzelle="baseForm.idParzelle"
            :id-parzelle-fruchtfolge="baseForm.idParzelleFruchtfolge"
            @changedSwitchState="newSwitchState"
            v-bind:is="baseForm.formOrder[0][0].component"/>
        </el-card>
      </div>
      <div v-if="visible.pflanzenkrankheiten">
        <el-row v-for="(base) in baseArray" :key="base.bundleId">
          <div v-if="base.options.length > 0">
            <el-card
              class="box-card">
              <div v-show="base.name"
                   slot="header" class="clearfix">
                <span><b>{{ base.name }}</b></span>
              </div>
              <div v-for="bundle in baseBundle" :key="bundle.bundleId">
                <div v-if="bundle.formName === base.name">
                  <form-component
                    :cdzForm="bundle"
                    :form-base-bundle="baseBundle"
                    :base-bundle="baseBundle"
                    :form-base-title="baseForm.name"
                    :form-name="bundle.formName"
                    :form-field-id="bundle.rmaId"
                    :form-attributes="baseEigenschaften"
                    :form-array="baseArray"
                    :form-big-build="bundle.firstOrder"
                    :form-tree="baseForm.formTree"
                    :form-branch="baseForm.formBranch"
                    :id-kultur="baseForm.idKultur"
                    :id-parzelle="baseForm.idParzelle"
                    :id-parzelle-fruchtfolge="baseForm.idParzelleFruchtfolge"
                    @setFormData="getFormData"
                    @updateGlobalData="updateGlobalData"
                    @emitFormData="emitFormData"
                    @newErrorState="newErrorState">
                  </form-component>
                </div>
              </div>
            </el-card>
          </div>
        </el-row>
      </div>
    </el-col>
    <el-col v-else>
      <el-card>
        <el-alert type="warning" :closable="false"><b>Für diese Option stehen noch keine Daten zur Verfügung!</b>
        </el-alert>
      </el-card>
    </el-col>
  </section>
</template>

<script>

import FormComponent from '../forms/form-component'
import { formMethods } from '../../mixins/form-methods'
import FormSwitchField from '../forms/switchs/form-switch-field'

export default {
  name: 'pflanzenkrankheiten-rueckmeldung',
  components: {
    'form-switch-field': FormSwitchField,
    'form-component': FormComponent
  },
  mixins: [formMethods],
  props: {
    baseForm: Object,
    baseBundle: Array,
    baseArray: Array,
    baseEigenschaften: Array
  },
  data () {
    return {
      visible: {
        pflanzenkrankheiten: false
      }
    }
  }
}
</script>

<style scoped>
</style>
