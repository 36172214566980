<template>
  <el-date-picker
    type="date"
    placeholder="Datum wählen"
    v-model="date"
    @change="submitChange"
    format="dd.MM.yyyy"
    value-format="yyyy-MM-dd"
    style="width: 100%;">
  </el-date-picker>
</template>

<script>
export default {
  name: 'FormInputDateField',
  props: {
    formField: {
      type: Object,
      default () {
        return {
          psmArray: undefined
        }
      }
    }
  },
  data () {
    return {
      date: '',
      psm: !!this.formField.psmArray
    }
  },
  watch: {
    cdzForm: {
      immediate: true,
      handler: function () {
        this.submitChange('')
      }
    }
  },
  methods: {
    submitChange: function (value) {
      this.$emit('setFormDate', {
        value: value
      })
      const id = this.strToNum(this.cdzForm.rmaId)
      if (!this.psm) {
        this.$emit('setNewFormData', {
          value: value,
          id: id > 0 ? id : undefined
        })
      } else {
        this.$emit('setPsmFormDate', {
          value: value
        })
      }
    }
  }
}
</script>
