export const formMethods = {
  data () {
    return {
      date: {},
      visible: {}
    }
  },
  methods: {
    setBundle (id, fieldId) {
      for (let i = 0; i < this.baseBundle.length; i++) {
        const bundle = this.baseBundle[i]
        if (bundle.rmaId === id && bundle.id === fieldId) {
          return bundle
        }
      }
    },
    newSwitchState (data) {
      this.visible[data.formBranch] = data.value
      this.$emit('newSwitchState', data)
    },
    updateNewSwitchState (data) {
      this.visible[data.formBranch] = data.value
      if (this.visible[data.formBranch] === false) {
        const bundles = this.baseBundle.filter(bundle => bundle.formId === data.baseFormId)
        bundles.forEach(bundle => {
          this.formDataErrors[bundle.bundleId].notValid = false
        })
        this.formDataErrors[data.baseFormId].isReady = true
        this.formDataErrors[data.baseFormId].notValid = false
        const localFormData = JSON.parse(localStorage.formData)
        if (localFormData[data.idKultur][data.idParzelle][data.idParzelleFruchtfolge][data.formTree][data.formBranch].length > 0) {
          localFormData[data.idKultur][data.idParzelle][data.idParzelleFruchtfolge][data.formTree][data.formBranch] = []
          this.formData = localFormData
          localStorage.rawFormData = JSON.stringify(localFormData)
          localStorage.formData = JSON.stringify(localFormData)
          this.formData = localFormData
          this.formDataChanged()
        }
      }
    },
    setFormDate (data) {
      this.date = data
      if (data.value && this.baseForm.formOrder.length <= 2) {
        this.$emit('setFormData', {
          id: this.baseForm.formOrder[1][0].fieldId,
          pId: this.baseForm.formOrder[1][0].fieldId,
          rmaId: this.baseForm.formOrder[1][0].id,
          key: 0,
          value: data.value,
          date: data.value,
          formTree: this.baseForm.formTree,
          formBranch: this.baseForm.formBranch
        })
      }
    },
    handleModalSubmit (data) {
      this.$emit('handleModalSubmit', data)
    },
    handleModalSelect (data) {
      let bbch = 0
      let idParzelle = data.selectedParzellen
      let idParzelleFruchtfolge = 0
      let idSorte = 0
      let idKultur = data.idKultur
      const parzellenNamen = []
      const parzellen = []
      let parzellenArray = []
      let nav = data.nav
      let parzelleObj = {}
      if (Array.isArray(data.selectedParzellen)) {
        nav = 'Parzelle'
        for (let i = 0; i < data.selectedParzellen.length; i++) {
          parzellenArray = this.getParzellen(data.selectedParzellen[i])[0]
          if (parseInt(parzellenArray.bbch) >= parseInt(bbch)) {
            bbch = parzellenArray.bbch
            idParzelle = parzellenArray.idParzelle
            idParzelleFruchtfolge = parzellenArray.idParzelleFruchtfolge
            idKultur = parzellenArray.idKultur
            idSorte = parzellenArray.idSorte
          }
          parzellenNamen.push(parzellenArray.name)
          parzellen.push(parzellenArray.parzelle)
        }
        parzelleObj = this.initParzelle(idParzelle, parzellen)
      } else {
        parzelleObj = this.getParzellen(data.selectedParzellen)[0]
      }
      const modalData = {
        parzelle: idParzelle,
        fruchtfolge: idParzelleFruchtfolge,
        kultur: idKultur,
        sorte: idSorte,
        bbch: bbch,
        namen: parzellenNamen,
        parzellen: parzellen
      }
      this.$router.push({
        name: nav,
        params: {
          modalData: modalData,
          modalCreate: true,
          parzelle: parzelleObj
        }
      })
    },
    getParzellen (id) {
      const parzellenArray = []
      for (let i = 0; i < this.parzellenFiltered.length; i++) {
        if (this.strToNum(this.parzellenFiltered[i].ID_Parzelle) === this.strToNum(id)) {
          console.log('this.parzellenFiltered[i].Fruchtfolge[0].Rueckmeldungen.KulturRueckmeldungen[0].RueckmeldungsWert', this.parzellenFiltered[i].Fruchtfolge[0].Rueckmeldungen.KulturRueckmeldungen)
          parzellenArray.push({
            bbch: this.parzellenFiltered[i].Fruchtfolge[0].Rueckmeldungen.KulturRueckmeldungen &&
              this.parzellenFiltered[i].Fruchtfolge[0].Rueckmeldungen.KulturRueckmeldungen[0]
              ? this.parzellenFiltered[i].Fruchtfolge[0].Rueckmeldungen.KulturRueckmeldungen[0].RueckmeldungsWert
              : '0',
            idParzelle: this.parzellenFiltered[i].ID_Parzelle,
            idParzelleFruchtfolge: this.parzellenFiltered[i].Fruchtfolge[0].ID_Parzelle_Fruchtfolge,
            idKultur: this.parzellenFiltered[i].Fruchtfolge[0].ID_Kultur,
            idSorte: this.parzellenFiltered[i].Fruchtfolge[0].ID_Sorte,
            name: this.parzellenFiltered[i].Name,
            parzelle: this.parzellenFiltered[i]
          })
        }
      }
      return parzellenArray
    },
    initParzelle (parzelle, parzellen) {
      let parzellenObj = {}
      for (let i = 0; i < parzellen.length; i++) {
        if (this.strToNum(parzellen[i].ID_Parzelle) === this.strToNum(parzelle)) {
          parzellenObj = parzellen[i]
        }
      }
      return parzellenObj
    },
    mergeField (base, datafield) {
      return base[datafield]
    },
    filterResponse (data, filter, ...datafields) {
      const dataArray = []
      // const dataField = this.mergeField(data, dataid)
      for (let i = 0; i < data.length; i++) {
        if (data[i] !== filter) {
          dataArray.push(data[i])
        }
      }
      return dataArray
    }
  }
}
