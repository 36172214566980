<template>
  <el-row type="flex" justify="center">
    <el-col :xs="Number(24)" :md="Number(12)" :lg="Number(12)"
            :xl="Number(6)">
      <el-card>
        <div slot="header">
          <b>Dein {{ navigation[0] }}</b>
        </div>
        <component
          :nav-items="navigation"
          :nav-start-row="navWetter.navStartRow"
          :nav-end-row="navWetter.navEndRow"
          :nav-icon-folder="navWetter.navIconFolder"
          :nav-is-link="true"
          v-bind:is="'nav-menu'"/>
      </el-card>
      <el-card>
        <div slot="header">
          <b>Deine Kulturen</b>
        </div>
        <el-row
          v-for="(kultur) in kulturenArray"
          :key="kultur.id"
          type="block"
          class="row-bg">
          <div class="grid-content">
            <el-col :span="24" style="margin-bottom: 10px">
              <router-link
                :to="{ name: 'Parzellen', params: { kultur: kultur, navigation: navigation }}"
                @updateGlobalData="updateGlobalData">
                <el-button style="width:100%" v-bind:style="{backgroundColor: kultur.value }" round>
                  <div style="float: left">
                    <el-image
                      style="max-width: 50px; width: auto; height: 50px"
                      :src="kultur.image">
                    </el-image>
                  </div>
                  <div style="color: white"><h1>{{ kultur.name }}</h1></div>
                </el-button>
              </router-link>
            </el-col>
          </div>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import kultur from '../api/kultur'
import wetterApi from '../api/wetter'
import NavMenu from '../components/nav/nav-menu'

export default {
  components: {
    'nav-menu': NavMenu
  },
  data () {
    return {
      kulturen: [],
      kulturenArray: [],
      kulturBundle: [],
      imgError: false,
      defaultImage: require('../assets/kulturen/default.png'),
      wetter: {
        wetterStationen: {
          wetterDaten: []
        }
      },
      navigation: {
        0: 'Wetter',
        1: 'Prognosen',
        2: 'Rueckmeldung'
      },
      navWetter: {
        navStartRow: 0,
        navEndRow: 0,
        navIconFolder: 'navigation'
      }
    }
  },
  created () {
    this.initKulturen()
    this.initWetterStationen()
    this.$store.commit('changeRouteName', '')
    this.$store.commit('setDeepStateNavigation', false)
    this.$store.commit('setNavigation', this.navigation)
  },
  methods: {
    filename (image) {
      let src = ''
      try {
        src = require('../assets/kulturen/' + image + '.png')
      } catch (error) {
        src = this.defaultImage
      }
      return src
    },
    filterKulturen: function () {
      this.kulturen = this.kulturen.filter(item => item.Parzellen.length > 0)
      this.kulturenArray = this.kulturenArray.filter(item => item.options.length > 0)
      localStorage.kulturen = JSON.stringify(this.kulturenArray)
    },
    initKulturen () {
      const self = this
      const idBetrieb = JSON.parse(localStorage.user).ID_Betrieb
      kultur.methods.getUserKulturen({
        ID_Betrieb: idBetrieb
      }).then(response => {
        self.kulturen = response.data.kulturen
        self.kulturenArray = response.data.kulturenArray
        self.kulturBundle = response.data.kulturBundle
        Array.from(response.data.kulturenArray).forEach(kultur => {
          const name = kultur.name.replace(' ', '')
          kultur.image = self.filename(name)
        })
        this.filterKulturen()
      })
    },
    initWetterStationen () {
      const self = this
      const idBetrieb = JSON.parse(localStorage.user).ID_Betrieb
      const dacomStation = 0
      wetterApi.methods.getWetterstationenVonBetrieb({
        ID_Betrieb: idBetrieb,
        DacomStation: dacomStation
      }).then(response => {
        self.wetter.wetterStationen = response.data.wetterStationen
        if (response.data.wetterStationen.length > 0) {
          this.initWetterDaten()
        }
      })
    },
    initWetterDaten () {
      const self = this
      let idWetterstation = ''
      for (let i = 0; i < this.wetter.wetterStationen.length; i++) {
        idWetterstation = this.wetter.wetterStationen[i].ID_Wetterstation
        wetterApi.methods.getWetterdaten({
          ID_Wetterstation: idWetterstation
        }).then(response => {
          self.$set(self.wetter.wetterStationen[i], 'wetterDaten', response.data.wetterDaten)
        })
      }
    }
  }
}
</script>

<!--<style scoped>-->
<!--.el-button&#45;&#45;warning {-->
<!--  color: #FFF;-->
<!--  background-color: #908574;-->
<!--  border-color: #483b2b;-->
<!--}-->
<!--</style>-->
