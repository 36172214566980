import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login'
import Dashboard from '../views/Dashboard.vue'
import Wetter from '../views/Wetter'
import Rueckmeldung from '../views/Rueckmeldung.vue'
import Prognosen from '../views/Prognosen'
import Parzellen from '@/views/Parzellen'
import Parzelle from '@/views/Parzelle'
import auth from '../auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: requireAuth
  },
  {
    path: '/wetter',
    name: 'Wetter',
    component: Wetter,
    beforeEnter: requireAuth
  },
  {
    path: '/rueckmeldung',
    name: 'Rueckmeldung',
    component: Rueckmeldung,
    beforeEnter: requireAuth
  },
  {
    path: '/prognosen',
    name: 'Prognosen',
    props: true,
    component: Prognosen,
    beforeEnter: requireAuth
  },
  {
    path: '/parzellen',
    name: 'Parzellen',
    component: Parzellen,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/parzelle',
    name: 'Parzelle',
    component: Parzelle,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'Login',
    props: true,
    component: Login,
    beforeEnter (to, from, next) {
      const token = localStorage.token
      if (token) {
        next({
          path: '/',
          name: 'Dashboard',
          query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/logout',
    beforeEnter (to, from, next) {
      auth.logout()
      next({
        path: '/login',
        name: 'Login',
        query: { redirect: to.fullPath }
      })
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: routes
})

export default router

function requireAuth (to, from, next) {
  if (!auth.loggedIn()) {
    next({
      path: '/login',
      name: 'Login',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
}
