export const highchartsOptions = {
  decimalPoint: ',',
  thousandsSep: '.',
  loading: 'Daten werden geladen...',
  months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  shortMonths: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  exportButtonTitle: 'Exportieren',
  printButtonTitle: 'Drucken',
  rangeSelectorFrom: 'Von',
  rangeSelectorTo: 'Bis',
  rangeSelectorZoom: 'Zeitraum',
  downloadPNG: 'Download als PNG-Bild',
  downloadJPEG: 'Download als JPEG-Bild',
  downloadPDF: 'Download als PDF-Dokument',
  downloadSVG: 'Download als SVG-Bild',
  resetZoom: 'Zoom zurücksetzen',
  resetZoomTitle: 'Zoom zurücksetzen'
}
