import { encode } from 'querystring-es3'
const axios = require('axios')

export default {
  data: function () {
    return {
      kulturQuery: {
        ID_Kultur: null,
        Name: null,
        ID_Betrieb: null
      },
      kulturen: []
    }
  },
  methods: {
    getAlleKulturen () {
      return axios.get(axios.defaults.baseURL + 'api/kultur/getAlleKulturen', encode(this.kulturQuery))
    },
    getKulturSorten () {
      return axios.post(axios.defaults.baseURL + 'api/kultur/getSorten', encode(this.kulturQuery))
    },
    getUserKulturen (query) {
      return axios.post(axios.defaults.baseURL + 'api/kultur/getUserKulturen/pwaCallUserKulturen', encode(query))
    }
  }
}
