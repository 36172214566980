export const formValidation = {
  props: {
    cdzForm: {
      type: Object,
      default () {
        return {
          id: 0,
          max: 0,
          min: 0,
          name: '',
          options: [],
          rmaId: 0,
          value: [Array, String]
        }
      }
    },
    formTree: String,
    formBranch: String,
    formFieldId: Number,
    idKultur: String,
    idParzelle: String,
    idParzelleFruchtfolge: String
  },
  data () {
    return {
      formData: {},
      kid: '',
      pid: '',
      fb: '',
      ft: '',
      pfid: '',
      rawFormData: {},
      foundFormData: false,
      formDataErrors: {},
      formError: false,
      baseDataErrors: {},
      baseError: false
    }
  },
  watch: {
    formDataErrors: {
      deep: true,
      handler: function (data) {
        const bundles = Object.keys(data).filter(key => data[key].notValid === true)
        this.formError = bundles.length > 0
        this.$store.commit('foundFormError', bundles.length > 0 ?? false)
      }
    }
  },
  methods: {
    initFormData: function () {
      this.formData = JSON.parse(localStorage.rawFormData)
      return this.formData
    },
    setFormData (data) {
      const idKultur = this.kid
      const idParzelle = this.pid
      const idParzelleFruchtfolge = this.pfid
      const formTree = data.formTree
      const formBranch = data.formBranch
      data.formId = this.getFormId(formTree, formBranch)
      if (formTree && formBranch) {
        const formArray = this.mergeFormData(idKultur, idParzelle, idParzelleFruchtfolge, formTree, formBranch)
        let index = formArray.findIndex(item => item.id === data.id)
        if (!data.pId) {
          this.toggleFormTypes(data, 'pId')
        } else if (data.key) {
          index = formArray.findIndex(item => item.key === data.key)
        }
        if (!data.date) {
          this.toggleFormTypes(data, 'date')
        }
        data = this.toggleFormTypes(data, 'formTree', 'formBranch')
        if (index >= 0) {
          if (formArray[index].pId && formArray[index].id) {
            if (formArray[index].date !== data.date) {
              for (let i = 0; i < formArray.length; i++) {
                formArray[index].date = data.date
              }
            }
            formArray[index].id = data.id
            formArray[index].pId = data.pId
          }
          formArray[index].value = data.value
        } else {
          formArray.push(data)
        }
      } else {
        this.formData.default.data.push(data)
      }
      this.formDataChanged()
    },
    getFormData (data) {
      this.$emit('setFormData', {
        id: data.id,
        rmaId: data.rmaId,
        pId: data.pId,
        key: data.key,
        value: data.value,
        formTree: data.formTree,
        formBranch: data.formBranch,
        formId: 0,
        date: this.thisValIsObject(data.date) ? data.date.value : data.date
      })
    },
    getFormId (formTree, formBranch) {
      let id = 0
      if (this.baseForm) {
        id = this.baseForm.id
      } else if (this.baseForms) {
        const index = this.baseForms.findIndex(item => item.formTree === formTree && item.formBranch === formBranch)
        id = this.baseForms[index].id
      }
      return id
    },
    strToNum (value) {
      return parseInt(value)
    },
    mergeFormData (kultur, parzelle, fruchtfolge, tree, branch) {
      const self = this
      kultur = self.strToNum(kultur)
      parzelle = self.strToNum(parzelle)
      fruchtfolge = self.strToNum(fruchtfolge)
      const formDataLocal = !this.formData[kultur] ? this.initFormData() : this.formData
      return tree && branch ? formDataLocal[kultur][parzelle][fruchtfolge][tree][branch] : formDataLocal[kultur][parzelle]
    },
    toggleFormTypes (data, ...keys) {
      for (const item in data) {
        for (let i = 0; i < keys.length; i++) {
          if (keys[i] === item) {
            this.$delete(data, item)
          }
        }
      }
      return data
    },
    removeFormData (data) {
      const self = this
      if (data.formTree && data.formBranch) {
        const idKultur = self.strToNum(data.idKultur)
        const idParzelle = self.strToNum(data.idParzelle)
        const idParzelleFruchtfolge = self.strToNum(this.pfid)
        const formArray = self.mergeFormData(idKultur, idParzelle, idParzelleFruchtfolge, data.formTree, data.formBranch)
        const index = formArray.findIndex(item => item.key === data.key)
        formArray.splice(index, 1)
        this.formDataChanged()
      }
    },
    deleteAllRows (data) {
      const self = this
      const formArray = self.mergeFormData(data.idKultur, data.idParzelle, data.idParzelleFruchtfolge, data.formTree, data.formBranch)
      if (formArray.length > 0) {
        formArray.splice(0)
        this.formDataChanged()
      }
    },
    emitFormData (data) {
      this.$emit('emitFormData', data)
    },
    setNewFormDataErrors (data) {
      Object.assign(this.formDataErrors, data)
      this.$emit('setNewFormDataErrors', data)
    },
    newErrorState (data, formId, baseBundle) {
      this.formDataErrors[Object.keys(data)[0]] = data[Object.keys(data)[0]]
      if (formId && baseBundle) {
        const bundles = baseBundle.filter(bundle => bundle.formId === formId)
        const bundleIds = Object.keys(this.formDataErrors).filter(key => bundles.find(b => b.bundleId === key))
        const result = bundleIds.find(bundle => this.formDataErrors[bundle].notValid === true)
        const notValid = { notValid: true, isReady: false }
        const isValid = { notValid: false, isReady: false }
        this.formDataErrors[formId] = result > 0 ? notValid : isValid
      }
      this.$emit('newErrorState', data, formId, baseBundle)
    },
    updateGlobalData (data) {
      this.$emit('updateGlobalData', data)
      this.kid = data.kid
      this.pid = data.pid
      this.pfid = data.pfid
      this.ft = data.ft
      this.fb = data.fb
    },
    setArray (arrayField) {
      if (this[arrayField]) {
        return this[arrayField]
      }
    },
    thisValIsObject (objValue) {
      const isObject = objValue && typeof objValue === 'object' && objValue.constructor === Object
      if (isObject) {
        return isObject
      }
    },
    formDataChanged () {
      const self = this
      const formDataStorage = window.btoa(localStorage.formData)
      const formDataLocal = window.btoa(JSON.stringify(self.formData))
      if (formDataStorage !== formDataLocal) {
        localStorage.rawFormData = localStorage.formData ?? JSON.stringify(self.formData)
        localStorage.formData = JSON.stringify(self.formData)
      }
    },
    notify (mode, title, message, duration, close) {
      this.$notify[mode]({
        title: title,
        message: message,
        duration: duration,
        showClose: close
      })
    }
  }
}
