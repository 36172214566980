<template>
  <section>
    <div>
      <canvas :id="'infektionsRisikoChart_'+randomId" :height="maxHeight"></canvas>
    </div>
  </section>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import moment from 'moment'

Chart.register(...registerables)

moment.locale('de-DE')

export default {
  props: {
    infektionsrisiko: {
      type: Array,
      default: () => {
        return []
      }
    },
    title: {
      type: String,
      default: 'Infektionsrisiko'
    },
    mainColor: {
      type: String,
      default: '#ff0000'
    },
    maxHeight: {
      type: Number,
      default: 250
    },
    phases: {
      type: Boolean,
      default: false
    },
    phaseData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      infektionsrisikoChart: null,
      infData: null,
      phaseDataArray: null,
      randomId: Math.floor(Math.random() * Math.floor(99999999999999))
    }
  },
  watch: {
    infektionsrisiko: {
      immediate: true,
      handler: function (infektionsrisiko) {
        if (infektionsrisiko.length > 0) {
          this.infData = infektionsrisiko
          this.phaseDataArray = this.phaseData
          this.createInfektionsrisikoGraph()
        }
      }
    }
  },
  methods: {
    removeDataFromChart (chart) {
      if (chart) {
        chart.destroy()
      }
    },
    cleanSource (source) {
      // using native JSON functions removes reactivity
      // so we can clone an object without mutating the original source
      // return JSON.parse(JSON.stringify(source))
    },
    createInfektionsrisikoGraph () {
      const self = this
      try {
        self.$nextTick(function () {
          const id = 'infektionsRisikoChart_' + this.randomId
          const ctx = document.getElementById(id).getContext('2d')

          self.removeDataFromChart(self.infektionsrisikoChart)
          const irData = self.infData
          const phaseData = self.phaseDataArray
          const datasets = []
          let color = '#ff0000'
          let phaseLabel = ''
          if (self.mainColor) {
            color = self.mainColor
          }
          // const riskTableKeys = null
          if (this.phases && irData.length > 0) {
            const temperaturSumme = 'Temperatur-Summe'
            irData.forEach((irdArray, key) => {
              color = '#' + Math.floor(Math.random() * 16777215).toString(16)
              phaseLabel = 'Phase' + (key + 1)
              if (phaseData[key].length > 0) {
                if (phaseData[key][0].color !== '') {
                  color = phaseData[key][0].color
                  phaseLabel = phaseData[key][0].name
                }
              }
              if (irdArray !== null) {
                irdArray.forEach((ird, irdKey) => {
                  // eslint-disable-next-line no-unused-vars
                  for (const [key, obj] of Object.entries(ird)) {
                    if (obj.riskTable[temperaturSumme]) {
                      obj.y = obj.riskTable[temperaturSumme].sumRelative
                    }
                  }
                })
              } else {
                irdArray = []
              }
              datasets.push({
                label: phaseLabel,
                data: irdArray[0],
                borderColor: color,
                backgroundColor: color,
                borderWidth: 0,
                // Changes this dataset to become a line
                yAxisID: 'y-axis-1',
                barThickness: 2
              })
            })
          } else {
            datasets.push({
              label: self.title,
              data: irData,
              borderColor: color,
              backgroundColor: color,
              fill: false,
              // Changes this dataset to become a line
              yAxisID: 'y-axis-1',
              pointRadius: 2,
              cubicInterpolationMode: 'default'
            })
            const dataArray = {}

            let riskTableKeys = null
            console.log('irData', irData)
            if (irData && irData.length > 0 && irData[0].riskTable) {
              riskTableKeys = Object.keys(irData[0].riskTable)
              irData.forEach(ird => {
                if (ird.riskTable) {
                  riskTableKeys.forEach(key => {
                    if (!dataArray[key]) {
                      dataArray[key] = []
                    }
                    dataArray[key].push({
                      x: moment(ird.x),
                      y: ird.riskTable[key].sumRelative
                    })
                  })
                }
              })
            }
            if (riskTableKeys) {
              riskTableKeys.forEach(key => {
                let color = 'yellow'
                switch (key) {
                  case 'Temperatur':
                    color = 'orange'
                    break
                  case 'Niederschlag':
                    color = 'blue'
                    break
                  case 'Blattnässe':
                    color = 'green'
                    break
                }
                datasets.push({
                  label: key,
                  data: dataArray[key],
                  borderColor: color,
                  backgroundColor: color,
                  fill: false,
                  yAxisID: 'y',
                  pointRadius: 0
                })
              })
            }
          }
          self.infektionsrisikoChart = new Chart(ctx, {
            type: 'bar',
            data: {
              datasets: datasets
            },
            options: {
              scales: {
                x: {
                  type: 'time',
                  time: {
                    unit: 'day',
                    unitStepSize: 1,
                    displayFormats: {
                      day: 'DD.MM'
                    }
                  },
                  ticks: {
                    maxRotation: 45,
                    minRotation: 45,
                    callback: function (val, index) {
                      return moment().format('DD.MM') === val ? 'Heute' : val
                    },
                    font: function (context) {
                      if (context.tick.label === 'Heute') {
                        return {
                          weight: 'bold'
                        }
                      }
                    }
                  }
                },
                y: {
                  type: 'linear',
                  display: false,
                  position: 'left',
                  min: 0,
                  max: 1
                }
              }
            }
          })
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}

</script>
