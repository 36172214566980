import Vue from 'vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/de'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import Highcharts from 'highcharts'
import { formValidation } from './mixins/form-validation'
import { highchartsOptions } from './mixins/highcharts-options'
import { apiConfig } from './mixins/api-config'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueCompositionAPI from '@vue/composition-api'
import { Chart, registerables } from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom'
const axios = require('axios')

axios.defaults.baseURL = apiConfig.apiUrl

let pwaSupport = false

if ('serviceWorker' in navigator) {
  pwaSupport = true // iOS 11 oder neuer
  navigator.serviceWorker.register('/service-worker.js').then(function (result) {
    console.log('Service Worker registriert')
  }, function (error) {
    console.log('Service Worker Registrierung fehlgeschlagen ' + error)
  })
} else {
  console.log('Service Worker nicht unterstützt')
}

window.onload = function () {
  if (pwaSupport) {
    const platform = navigator.platform
    if (platform === 'iPhone' || platform === 'iPad') {
      // Die App ist noch nicht installiert
      if (!navigator.standalone) {
        const lastShown = parseInt(localStorage.getItem('lastShown'))
        const now = new Date().getTime()
        // lastShown NaN – App wurde noch nie geladen und Anweisung seit 7 Tagen nicht gezeigt
        if (isNaN(lastShown) || (lastShown + 1000 * 60 * 60 * 24 * 7) <= now) {
          document.getElementById('instructions').style.display = 'block'
          localStorage.setItem('lastShown', now)
        }
      }
    }
  }
}

// function hideInstructions () {
//   document.getElementById('instructions').style.display = 'none'
// }

Chart.register(...registerables, zoomPlugin)
Vue.use(VueCompositionAPI)
Vue.prototype.$formData = {}
Vue.prototype.$rawFormData = {}
Vue.config.productionTip = false
Vue.use(ElementUI, { locale })
Vue.use(VueSweetalert2)
Vue.mixin(formValidation)
Vue.use(VueMoment, {
  moment
})

Highcharts.setOptions({
  lang: highchartsOptions
})

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
