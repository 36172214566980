<template>
  <section>
    <el-switch
      v-model="value"
      :name="cdzForm.name"
      :active-text="cdzForm.options[1]"
      :inactive-text="cdzForm.options[0]"
      @change="changedSwitchState">
    </el-switch>
  </section>
</template>

<script>
export default {
  name: 'FormSwitchField',
  props: {
    formField: {
      type: Object,
      default () {
        return {
          formType: 'Switch',
          formName: '',
          activeText: '',
          inactiveText: '',
          state: false,
          disabled: false
        }
      }
    }
  },
  data () {
    return {
      value: this.cdzForm.state ?? false
    }
  },
  watch: {},
  methods: {
    changedSwitchState (value) {
      this.$emit('changedSwitchState', {
        value: value,
        idKultur: this.idKultur,
        idParzelle: this.idParzelle,
        idParzelleFruchtfolge: this.idParzelleFruchtfolge,
        formTree: this.formTree,
        formBranch: this.formBranch,
        baseFormId: this.cdzForm.formId
      })
    }
  }
}
</script>
