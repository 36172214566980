<template>
  <section>
    <el-form :model="psmFeedbacks"
             v-if="formFields.formArray"
             ref="psmFeedbacks"
             label-width="120px"
             label-position="top">
      <el-form-item
        v-for="(row, index) in psmFeedbacks.rows"
        :key="row.key"
        :prop="'rows.' + index + '.psmForm'">
        <el-form-item
          :prop="'rows.' + index + '.psmType'"
          label="psmType" ref="psmType">
          <template slot="label">
            <b>Typ</b>
          </template>
          <el-select
            v-model="row.typeId"
            placeholder="Bitte wählen"
            @change="resetSelectField"
            width="80%"
            clearable>
            <el-option
              v-for="type in psmTypes"
              :key="type.ID_Type"
              :label="type.Name"
              :value="strToNum(type.ID_Type)" :disabled="type.disabled">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :prop="'rows.' + index + '.psmSelect'"
          label="psm">
          <template v-if="row.typeId !== ''" slot="label">
            <b>Bezeichnung</b>
          </template>
          <template v-else slot="label">
            <el-divider></el-divider>
          </template>
          <el-alert type="error" v-if="row.typeId === ''" :closable="false"><b>Bitte zuerst einen Typ wählen!</b>
          </el-alert>
          <form-select-single
            v-model="row.id"
            v-show="row.typeId"
            :base-bundle="baseBundle"
            :cdz-form="{id:cdzForm.id, bundleId: bundleIndex + row.key, formId: cdzForm.formId, options: row.options}"
            :form-field="{psm: row.options}"
            :is-type="row.typeId"
            :form-field-id="strToNum(row.key)"
            :form-tree="formTree"
            :form-branch="formBranch"
            :id-kultur="idKultur"
            :id-parzelle="idParzelle"
            :id-parzelle-fruchtfolge="idParzelleFruchtfolge"
            :reset-select="resetSelect"
            :reset-value="resetedValue"
            :changed-form-field="formFields.formArray[row.typeId]"
            @updateFormField="updateFormField"
            @resetBool="resetBool"
            @updateGlobalData="updateGlobalData"
            @resetOptions="resetOptions"
            @setFormData="getFormData"
            @newErrorState="newErrorState"
            @changeSelect="changeMinMax($event, row.key, row.id)">
          </form-select-single>
        </el-form-item>
        <el-form-item
          v-if="row.typeId !== ''"
          :prop="'rows.' + index + '.psmInput'"
          label="Menge">
          <template slot="label">
            <b>Menge</b>
          </template>
          <form-input-num-scale
            v-model="row.key"
            :form-field="{
            value: row.value,
            pId: row.id,
            range: {
              min: row.min,
              max: row.max
              },
            precision: 2,
            step: 0.01
          }"
            :form-field-id="row.key"
            :form-tree="formTree"
            :form-branch="formBranch"
            :form-field-date="formFieldDate"
            :form-field-rows="psmFeedbacks.rows"
            :id-kultur="idKultur"
            :id-parzelle="idParzelle"
            :id-parzelle-fruchtfolge="idParzelleFruchtfolge"
            @cacheValue="setNewValue"
            @setNewFormData="getFormData">
          </form-input-num-scale>
        </el-form-item>
        <el-divider></el-divider>
        <el-button v-if="row.key > 1" @click.prevent="removeRow(row)">
          <i class="el-icon-delete"></i>
        </el-button>
        <el-divider v-if="row.key > 1"></el-divider>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="addRow">Weiteres Pflanzenschutzmittel</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>

import FormSelectSingle from './form-select-single'
import FormInputNumScale from '../inputs/form-input-num-scale'

export default {
  name: 'FormSelectDynamic',
  components: {
    FormInputNumScale,
    FormSelectSingle
  },
  props: {
    baseForm: Array,
    baseBundle: Array,
    formVisible: Boolean,
    formField: {
      type: Object,
      default () {
        return {
          psmArray: [],
          pkArray: []
        }
      }
    },
    formFieldId: Number,
    formFieldDate: {
      type: Object
    },
    formBaseBundle: Object,
    formTree: String,
    formBranch: String,
    psmTypes: Array
  },
  data () {
    return {
      bundleIndex: 10101010,
      psmFeedbacks: {
        rows: [{
          key: 1,
          min: 0,
          max: 1,
          id: undefined,
          value: 0,
          typeId: '',
          options: {},
          select: '',
          disabled: true
        }]
      },
      pkFeedbacks: {
        rows: this.formField.pkArray
      },
      Rueckmeldungsarten: [],
      rueckmeldungsart: {
        ID_Rueckmeldungsart: ''
      },
      disabled: false,
      psmCatchedRows: {
        rows: []
      },
      isSelected: [],
      isType: '',
      formFields: this.formField,
      resetSelect: false,
      resetedValue: ''
    }
  },
  watch: {
    formField: {
      deep: true,
      handler: function (data) {
        this.formFields.formArray = data.formArray
      }
    },
    psmFeedbacks: {
      deep: true,
      handler: function (psmFeedbacks) {
        const self = this
        const check = self.formFields.formArray
        let counter = 0
        for (const item in check) {
          counter = check[item].filter(itm => itm.disabled === true).length
          const index = this.psmTypes.findIndex(i => i.ID_Type === item)
          this.$emit('reorderPsmType', {
            counter: counter,
            index: index
          })
        }
        const formData = JSON.parse(localStorage.formData)[this.idKultur][this.idParzelle][this.idParzelleFruchtfolge][this.formTree][this.formBranch]
        for (let i = 0; i < psmFeedbacks.rows.length; i++) {
          if (psmFeedbacks.rows[i].value) {
            this.psmFeedbacks.rows[i].value = formData[i].value > psmFeedbacks.rows[i].value ? formData[i].value : psmFeedbacks.rows[i].value
          }
        }
      }
    },
    isSelected: {
      deep: true,
      handler: function (selectedOptions) {
        for (let i = 0; i < selectedOptions.length; i++) {
          const formFieldsIndex = this.formFields.formArray[this.isType].findIndex(item => item.id === selectedOptions[i])
          if (formFieldsIndex >= 0) {
            this.formFields.formArray[this.isType][formFieldsIndex].disabled = true
          }
        }
      }
    }
  },
  methods: {
    resetBool (bool) {
      this.resetSelect = bool
    },
    updateFormField (data) {
      this.formFields.formArray[this.isType] = data
      this.$emit('updatePsmOptions', data, this.isType)
    },
    resetSelectField (type) {
      this.resetSelect = true
      const self = this
      const data = this.formFields.formArray[type]
      const selectedOptions = self.isSelected
      self.isType = type
      for (let i = 0; i < selectedOptions.length; i++) {
        const index = data.findIndex(item => item.id === selectedOptions[i])
        if (index >= 0) {
          data[index].disabled = false
        }
      }
      this.formFields.formArray[type] = data
    },
    resetOptions (id) {
      if (id) {
        const selectedIndex = this.isSelected.findIndex(item => item === id)
        for (let i = 0; i < this.formFields.formArray.length; i++) {
          const formFieldIndex = this.formFields.formArray[i].findIndex(item => item.id === id)
          if (formFieldIndex >= 0) {
            this.formFields.formArray[i][formFieldIndex].disabled = true
          }
        }
        const data = this.formField.formArray[this.isType]
        this.$emit('updatePsmOptions', data, this.isType)
        this.delete(this.isSelected, selectedIndex)
      } else {
        const data = this.formField.formArray[this.isType]
        this.$emit('updatePsmOptions', data, this.isType)
      }
    },
    changeMinMax (event, key, value) {
      for (let i = 0; i < this.psmFeedbacks.rows.length; i++) {
        if (this.psmFeedbacks.rows[i].key === key) {
          if (event >= 0) {
            this.psmFeedbacks.rows[i].min = this.formField.formArray[this.isType][event].min
            this.psmFeedbacks.rows[i].max = this.formField.formArray[this.isType][event].max
            this.psmFeedbacks.rows[i].id = this.formField.formArray[this.isType][event].id
            this.isSelected.push(this.formField.formArray[this.isType][event].id)
          }
        }
      }
    },
    setNewValue (data) {
      const index = this.psmCatchedRows.rows.findIndex(item => item.key === data.key)
      if (this.psmCatchedRows.rows[index]) {
        this.psmCatchedRows.rows[index].value = data.value
      }
    },
    removeRow (item) {
      const index = this.psmFeedbacks.rows.indexOf(item)
      const id = this.psmFeedbacks.rows[index].id
      const key = this.psmFeedbacks.rows[index].key
      if (id) {
        this.$emit('emitFormData', {
          id: id,
          key: key,
          idKultur: this.idKultur,
          idParzelle: this.idParzelle,
          formTree: this.formTree,
          formBranch: this.formBranch
        })
      }
      if (index !== -1) {
        this.psmFeedbacks.rows.splice(index, 1)
        this.psmCatchedRows.rows.splice(index, 1)
      }
    },
    addRow () {
      this.psmFeedbacks.rows.push({
        key: this.psmFeedbacks.rows.length + 1,
        value: '',
        min: 0,
        max: 1,
        typeId: '',
        options: [],
        select: '',
        disabled: false
      })
    },
    delete (array, index) {
      this.$delete(array, index)
    }
  }
}
</script>
