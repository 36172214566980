<template>
  <section>
    <el-col
      v-if="baseForm.formOrder.length > 0"
      :span="6"
      :xs="24">
      <div class="grid-content">
        <el-card>
          <component
            :cdzForm="setBundle(baseForm.formOrder[0][0].id, baseForm.formOrder[0][0].fieldId)"
            :form-main-attributes="baseBundle"
            @changedSwitchState="newSwitchState"
            :form-tree="baseForm.formTree"
            :form-branch="baseForm.formBranch"
            :id-kultur="baseForm.idKultur"
            :id-parzelle="baseForm.idParzelle"
            :id-parzelle-fruchtfolge="baseForm.idParzelleFruchtfolge"
            v-bind:is="baseForm.formOrder[0][0].component"/>
        </el-card>
      </div>
      <div v-if="visible.pflanzenschutz">
        <el-card>
          <div slot="header">
            <div>
              <el-alert v-show="!date.value" type="warning" :closable="false"><b>Bitte zuerst das Datum festlegen!</b>
              </el-alert>
              <span v-show="date.value"><b>Datum</b></span>
            </div>
          </div>
          <component
            :cdzForm="setBundle(baseForm.formOrder[1][0].id, baseForm.formOrder[1][0].fieldId)"
            :form-main-attributes="baseBundle"
            @setFormDate="setFormDate"
            v-bind:is="baseForm.formOrder[1][0].component"/>
        </el-card>
        <el-card v-if="date.value">
          <span><b>Pflanzenschutzmittel</b></span>
          <el-divider></el-divider>
          <el-form :model="rows"
                   v-if="date.value"
                   ref="rows"
                   label-width="120px"
                   label-position="top">
            <el-form-item
              v-for="(row, index) in rows.operation"
              :key="row.key"
              :prop="'row' + index + 'key'">
              <el-form-item
                prop="type"
                label="Typ" :inline-message="true" :error="row.typeError">
                <template slot="label">
                  <b>Typ</b>
                </template>
                <el-select
                  v-model="row.type"
                  @change="filterPsms(row.key)"
                  @clear="clearRow(row.key)"
                  clearable>
                  <el-option
                    v-for="type in types"
                    :key="type.ID_Type"
                    :label="type.Name"
                    :value="strToNum(type.ID_Type)">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-show="row.type"
                prop="psm"
                label="Bezeichnung" :inline-message="true" :error="row.psmError">
                <template slot="label">
                  <b>Bezeichnung</b>
                </template>
                <el-select v-if="row.psms" v-model="row.psm" @change="setMinMax(row.key, row.psm)"
                           filterable placeholder="Bitte wählen">
                  <el-option
                    v-for="(psm, index) in row.psms.filter(item => item.typeId === row.type)"
                    :key="index"
                    :label="psm.name"
                    :value="psm.id"
                    :disabled="psm.disabled">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                v-show="row.psm"
                prop="quantity"
                label="Menge"
                :inline-message="true"
                :error="row.mengeError">
                <template slot="label">
                  <b v-if="row.einheit === 'l'">Menge in ( {{ row.einheit }} / Liter )</b>
                  <b v-else-if="row.einheit === 'kg'">Menge in ( {{ row.einheit }} )</b>
                  <b v-else>Menge </b>
                </template>
                <el-input
                  class="smaller"
                  type="number"
                  v-model="row.quantity"
                  :precision="2"
                  :step="0.01"
                  :min="row.min"
                  :max="row.max"
                  @input="quantityCheck(row)"
                  @change="quantityCheck(row)">
                  <el-button
                    slot="prepend"
                    icon="el-icon-minus"
                    @click="quantityMinus(row)">
                  </el-button>
                  <el-button
                    slot="append"
                    icon="el-icon-plus"
                    @click="quantityPlus(row)">
                  </el-button>
                </el-input>
              </el-form-item>
              <div style="padding-top: 25px;">
                <el-button v-if="index > 0" @click="removeRow(row.key)">
                  <i class="el-icon-delete"></i>
                </el-button>
              </div>
              <el-divider></el-divider>
            </el-form-item>
            <el-form-item>
              <el-button type="success" @click="addRow">Weiteres Pflanzenschutzmittel</el-button>
            </el-form-item>
          </el-form>
        </el-card>
      </div>
    </el-col>
    <el-col v-else>
      <el-card>
        <el-alert type="warning" :closable="false"><b>Für diese Option stehen noch keine Daten zur Verfügung!</b>
        </el-alert>
      </el-card>
    </el-col>
  </section>
</template>

<script>

import FormSwitchField from '../forms/switchs/form-switch-field'
import FormInputDateField from '../forms/inputs/form-input-date-field'
import FormSelectDynamic from '../forms/selects/form-select-dynamic'
import { formMethods } from '../../mixins/form-methods'
import moment from 'moment'

export default {
  name: 'pflanzenschutz-rueckmeldung',
  mixins: [formMethods],
  components: {
    'form-switch-field': FormSwitchField,
    'form-date-field': FormInputDateField,
    'form-select-dynamic': FormSelectDynamic
  },
  props: {
    baseForm: Object,
    baseBundle: Array,
    baseArray: Array,
    baseEigenschaften: Array,
    psmTypes: Array
  },
  data () {
    return {
      kid: this.idKultur,
      pid: this.idParzelle,
      pfid: this.idParzelleFruchtfolge,
      ft: this.formTree,
      fb: this.formBranch,
      visible: {
        pflanzenschutz: false
      },
      pflanzenschutzmittel: {},
      rawPsms: this.baseArray,
      types: [],
      typeStatus: false,
      dateError: '',
      saatDatum: '',
      elForm: {},
      rows: {
        operation: [{
          key: 0,
          value: '',
          min: 0,
          max: 1,
          einheit: '',
          quantity: null,
          psm: null,
          type: null,
          psms: this.rawPsms,
          formId: this.setBundle(this.baseForm.formOrder[2][0].id, this.baseForm.formOrder[2][0].fieldId).formId,
          bundleId: 55 + this.setBundle(this.baseForm.formOrder[2][0].id, this.baseForm.formOrder[2][0].fieldId).bundleId,
          psmError: 'Das ist ein Pflichtfeld',
          typeError: 'Bitte zuerst einen Typ festlegen!',
          mengeError: 'Der Wert sollte größer 0 sein!',
          valid: false,
          disabled: false
        }]
      },
      validation: true,
      inputNum: [{
        num: 0
      }]
    }
  },
  watch: {
    visible: {
      deep: true,
      handler: function (data) {
        const state = data.pflanzenschutz
        this.validation = state
        const psmBundleId = this.setBundle(this.baseForm.formOrder[0][0].id, this.baseForm.formOrder[0][0].fieldId).bundleId
        const baseId = this.setBundle(this.baseForm.formOrder[0][0].id, this.baseForm.formOrder[0][0].fieldId).formId
        const elForm = this.elForm
        if (!state) {
          elForm[psmBundleId].notValid = false
          elForm[psmBundleId].isReady = true
          for (let i = 0; i < this.rows.operation.length; i++) {
            elForm[this.rows.operation[i].bundleId].notValid = false
            elForm[this.rows.operation[i].bundleId].isReady = true
            this.removeRow(this.rows.operation[i].key)
          }
          this.elForm = elForm
          this.$nextTick(function () {
            this.$emit('newErrorState', elForm, baseId, this.baseBundle)
          })
        }
        if (state !== this.validate()) {
          elForm[psmBundleId].notValid = true
          elForm[psmBundleId].isReady = false
          elForm[psmBundleId].canceled = false
        } else {
          elForm[psmBundleId].notValid = false
          elForm[psmBundleId].isReady = true
          elForm[psmBundleId].canceled = true
          for (let i = 0; i < this.rows.operation.length; i++) {
            elForm[this.rows.operation[i].bundleId].notValid = false
            elForm[this.rows.operation[i].bundleId].isReady = true
            this.removeRow(this.rows.operation[i].key)
          }
          this.elForm = elForm
        }
      }
    },
    date: {
      deep: true,
      handler: function (date) {
        if (date.value) {
          for (let i = 0; i < this.rows.operation.length; i++) {
            if (this.rows.operation[i].quantity > 0) {
              this.submitChange(this.rows.operation[i])
            }
          }
        }
        const spritzDatum = Number(moment(date.value, 'YYYY-MM-DD').format('X'))
        const maxDatum = Number(moment(moment().add(7, 'days')).format('X'))
        const saatDatum = Number(moment(this.saatDatum, 'DD.MM.YYYY', 'de').format('X'))
        if (date.value) {
          if (spritzDatum > maxDatum) {
            this.dateError = 'Das ist ein Pflichtfeld'
            this.validation = false
            this.notify('error', 'Das Spritzdatum sollte nicht weiter als eine Woche liegen!')
            this.date.value = ''
          } else if (spritzDatum < saatDatum) {
            this.dateError = 'Das ist ein Pflichtfeld'
            this.validation = false
            this.notify('error', 'Das Spritzdatum darf nicht vor dem Saat Datum liegen!')
            this.date.value = ''
          } else if (spritzDatum >= saatDatum) {
            this.dateError = ''
          }
        } else {
          this.dateError = 'Das ist ein Pflichtfeld'
        }
      }
    },
    psmTypes: {
      immediate: true,
      handler: function (psmTypes) {
        if (psmTypes) {
          this.types = psmTypes.filter(item => this.rawPsms.find(psm => psm.typeId === this.strToNum(item.ID_Type)))
        }
      }
    },
    rows: {
      deep: true,
      handler: function (rows) {
        for (let i = 0; i < rows.operation.length; i++) {
          if (rows.operation[i].quantity > 0) {
            this.elFormData(rows.operation[i].bundleId)
            this.submitChange(rows.operation[i])
          }
        }
      }
    },
    $props: {
      immediate: true,
      handler: function (data) {
        data = {
          kid: data.idKultur,
          pid: data.idParzelle,
          pfid: data.idParzelleFruchtfolge,
          ft: data.formTree,
          fb: data.formBranch
        }
        this.$emit('updateGlobalData', data)
      }
    },
    elForm: {
      deep: true,
      handler: function (elForm) {
        let baseId = 0
        for (let i = 0; i < this.rows.operation.length; i++) {
          if (this.rows.operation[i].valid) {
            elForm[this.rows.operation[i].bundleId].notValid = false
            elForm[this.rows.operation[i].bundleId].msg = false
            baseId = this.rows.operation[i].formId
          } else {
            if (elForm[this.rows.operation[i].bundleId]) {
              elForm[this.rows.operation[i].bundleId].notValid = true
              elForm[this.rows.operation[i].bundleId].msg = true
              baseId = this.rows.operation[i].formId
            }
          }
          let validState = []
          validState = this.rows.operation
          validState = validState.filter(item => item.valid && item.psm !== null)
          const psmBundleId = this.setBundle(this.baseForm.formOrder[0][0].id, this.baseForm.formOrder[0][0].fieldId).bundleId
          if (validState.length === this.rows.operation.length || this.elForm[psmBundleId].canceled) {
            elForm[psmBundleId].notValid = false
            elForm[psmBundleId].isReady = true
          } else {
            elForm[psmBundleId].notValid = true
            elForm[psmBundleId].isReady = false
          }
          this.elForm = elForm
          this.formDataErrors[this.rows.operation[i].bundleId] = elForm[this.rows.operation[i].bundleId]
          // this.setErrorMessage(elForm, this.rows.operation[i].bundleId)
          const baseBundle = this.baseBundle
          if (this.visible.pflanzenschutz) {
            this.$emit('newErrorState', elForm, baseId, baseBundle)
          }
        }
      }
    }
  },
  created () {
    this.elFormData(this.setBundle(this.baseForm.formOrder[0][0].id, this.baseForm.formOrder[0][0].fieldId).bundleId)
  },
  methods: {
    quantityCheck (row) {
      const result = parseFloat(row.quantity)
      const check = result > parseFloat(row.max) || result < parseFloat(row.min)
      const bundleId = parseInt(row.bundleId)
      if (check || isNaN(result)) {
        row.mengeError = 'Die Menge sollte zwischen ' + row.min + ' und ' + row.max + ' liegen!'
        this.elForm[bundleId].notValid = true
        this.elForm[bundleId].isReady = false
        row.valid = false
      } else {
        row.mengeError = ''
        this.elForm[bundleId].notValid = false
        this.elForm[bundleId].isReady = true
        row.valid = true
      }
    },
    quantityMinus (row) {
      let result = parseFloat(row.quantity)
      result = parseFloat(result - 0.01).toFixed(2)
      row.quantity = result >= row.min ? result : row.min
      this.submitChange(row)
    },
    quantityPlus (row) {
      let result = parseFloat(row.quantity)
      result = parseFloat(result + 0.01).toFixed(2)
      row.quantity = result <= row.max ? result : row.max
      this.submitChange(row)
    },
    elFormDataUpdate () {
      let validState = []
      validState = this.rows.operation
      validState = validState.filter(item => item.psm !== null && item.valid)
      const psmBundleId = this.setBundle(this.baseForm.formOrder[0][0].id, this.baseForm.formOrder[0][0].fieldId).bundleId
      if (validState.length === this.rows.operation.length) {
        this.elForm[psmBundleId].notValid = false
        this.elForm[psmBundleId].isReady = true
      } else {
        this.elForm[psmBundleId].notValid = true
        this.elForm[psmBundleId].isReady = false
      }
    },
    elFormData (bundleId = false) {
      this.elForm = Object.assign({}, this.elForm, {
        [[this.cdzForm.bundleId ?? bundleId]]: {
          msg: '',
          valid: undefined,
          isReady: false
        }
      })
    },
    setErrorMessage (data = false, bundleId = false) {
      this.errorMessage = data ? data[this.cdzForm.bundleId ?? bundleId].msg : ''
    },
    validate () {
      let validState = this.rows.operation.length
      for (let i = 0; i < this.rows.operation.length; i++) {
        this.elFormData(this.rows.operation[i].bundleId)
        if (this.rows.operation[i].quantity > 0) {
          this.submitChange(this.rows.operation[i])
          validState = -1
        }
      }
      return validState <= 0
    },
    submitChange (row) {
      const cdzForm = this.setBundle(this.baseForm.formOrder[2][0].id, this.baseForm.formOrder[2][0].fieldId)
      const rowIndex = this.getIndex(this.rows.operation, row.key, 'key')
      this.quantityCheck(this.rows.operation[rowIndex])
      if (row.psm) {
        this.$emit('setFormData', {
          id: row.psm,
          pId: row.psm,
          rmaId: cdzForm.rmaId,
          key: 55 + cdzForm.bundleId + row.key,
          value: row.quantity,
          date: this.date.value,
          formTree: 'rueckmeldung',
          formBranch: 'pflanzenschutz'
        })
      }
      this.elFormDataUpdate()
    },
    getIndex (data, row, key) {
      return data.findIndex(item => item[key] === row)
    },
    setToDefault (data, fallBack) {
      for (let i = 0; i < data.length; i++) {
        data[i][fallBack] = false
      }
      return data
    },
    getMinMax (row) {
      const rowIndex = this.getIndex(this.rows.operation, row, 'key')
      const data = {
        min: 0,
        max: 1,
        einheit: false
      }
      if (rowIndex >= 0) {
        const psmID = this.rows.operation[rowIndex].psm
        const psmIndex = this.getIndex(this.rawPsms, psmID, 'id')
        data.min = psmIndex > 0 ? parseFloat(this.rawPsms[psmIndex].min) : 0
        data.max = psmIndex > 0 ? parseFloat(this.rawPsms[psmIndex].max) : 1
        data.einheit = psmIndex > 0 ? this.rawPsms[psmIndex].einheit : ''
      }
      return data
    },
    setMinMax (row, psm = false, onDelete = false) {
      let rawPsms = this.rawPsms
      if (psm) {
        let psmIndex = this.getIndex(rawPsms, psm, 'id')
        rawPsms = this.setToDefault(rawPsms, 'disabled')
        if (psmIndex >= 0) {
          rawPsms[psmIndex].disabled = true
        }
        for (let i = 0; i < this.rows.operation.length; i++) {
          psmIndex = this.getIndex(rawPsms, this.rows.operation[i].psm, 'id')
          if (psmIndex >= 0) {
            rawPsms[psmIndex].disabled = true
          }
        }
      }
      const rowIndex = this.getIndex(this.rows.operation, row, 'key')
      const defaultData = {
        min: 0,
        max: 1
      }
      const data = rowIndex >= 0 ? this.getMinMax(row) : defaultData
      if (!onDelete) {
        this.rows.operation[rowIndex].min = data.min
        this.rows.operation[rowIndex].max = data.max
        this.rows.operation[rowIndex].einheit = data.einheit
        this.rows.operation[rowIndex].quantity = data.min
        if (this.rows.operation[rowIndex].quantity <= 0) {
          this.rows.operation[rowIndex].mengeError = 'Der Wert sollte größer 0 sein!'
        } else {
          this.rows.operation[rowIndex].mengeError = ''
        }
        this.rows.operation[rowIndex].psmError = ''
        this.rows.operation[rowIndex].psms = rawPsms
      }
    },
    filterPsms (row) {
      this.typeStatus = false
      const rowIndex = this.getIndex(this.rows.operation, row, 'key')
      const rawPsms = this.rawPsms
      if (rowIndex >= 0) {
        this.rows.operation[rowIndex].psms = rawPsms
        this.rows.operation[rowIndex].psm = null
        this.rows.operation[rowIndex].typeError = ''
        this.rows.operation[rowIndex].quantity = 0
        this.rows.operation[rowIndex].mengeError = ''
        if (this.rows.operation[rowIndex].type > 0 && this.rows.operation[rowIndex].type !== null) {
          const type = this.rows.operation[rowIndex].type
          this.rows.operation[rowIndex].psms = this.rows.operation[rowIndex].psms.filter(item => item.typeId === type)
        } else {
          this.rows.operation[rowIndex].psms = rawPsms
          this.rows.operation[rowIndex].typeError = 'Bitte zuerst einen Typ festlegen!'
        }
      }
      this.elFormDataUpdate()
    },
    clearRow (key) {
      const index = this.rows.operation.findIndex(item => item.key === key)
      const bundleId = this.rows.operation[index].bundleId
      this.$nextTick(function () {
        this.rows.operation[index].quantity = 0
        this.setMinMax(index, true, true)
      })
      this.$emit('emitFormData', {
        key: bundleId,
        idKultur: this.idKultur,
        idParzelle: this.idParzelle,
        formTree: 'rueckmeldung',
        formBranch: 'pflanzenschutz'
      })
      this.elFormDataUpdate()
    },
    removeRow (key) {
      const index = this.rows.operation.findIndex(item => item.key === key)
      const bundleId = this.rows.operation[index].bundleId
      for (let i = 0; i < this.rows.operation.length; i++) {
        if (i === index) {
          this.rows.operation[i].quantity = -1
        }
      }
      this.$emit('emitFormData', {
        key: bundleId,
        idKultur: this.idKultur,
        idParzelle: this.idParzelle,
        formTree: 'rueckmeldung',
        formBranch: 'pflanzenschutz'
      })
      if (index >= 1) {
        this.rows.operation.splice(index, 1)
        this.setMinMax(key, true, true)
      }
      this.reorderRows()
    },
    reorderRows () {
      for (let i = 0; i < this.rows.operation.length; i++) {
        this.rows.operation[i].key = i
      }
    },
    addRow () {
      const length = this.rows.operation.length - 1
      this.rows.operation.push({
        key: length + 1,
        formId: this.setBundle(this.baseForm.formOrder[2][0].id, this.baseForm.formOrder[2][0].fieldId).formId,
        bundleId: 55 + this.setBundle(this.baseForm.formOrder[2][0].id, this.baseForm.formOrder[2][0].fieldId).bundleId + (length + 1),
        value: '',
        min: 0,
        max: 1,
        einheit: '',
        quantity: -1,
        type: null,
        psm: null,
        psms: this.baseArray,
        psmError: 'Das ist ein Pflichtfeld',
        typeError: 'Bitte zuerst einen Typ festlegen!',
        mengeError: 'Der Wert sollte größer 0 sein!',
        valid: false,
        disabled: false
      })
      this.elFormDataUpdate()
    }
  }
}
</script>

<style scoped>
.smaller {
  width: 75%;
}
</style>
