<template>
  <section>
    <div class="chart-container" style="position: relative; height:60vh; width:100%">
      <canvas id="wetterChart"></canvas>
    </div>
  </section>
</template>

<script>
import { Chart, registerables } from 'chart.js'
import moment from 'moment'

Chart.register(...registerables)

moment.locale('de-DE')

export default {
  props: {
    wetterDaten: {
      type: Object,
      default () {
        return {
          tagesWerteNiederschlag: [],
          tagesWerteTemperatur: []
        }
      }
    }
  },
  data () {
    return {
      wetterChart: null,
      options: {}
    }
  },
  watch: {
    wetterDaten: {
      immediate: true,
      handler: function (data) {
        if (data) {
          this.createWetterGraph()
        }
      }
    }
  },
  methods: {
    removeDataFromChart (chart) {
      if (chart) {
        chart.destroy()
      }
    },
    createWetterGraph () {
      const self = this
      try {
        self.removeDataFromChart(self.wetterChart)
        self.$nextTick(function () {
          const ctxContent = document.getElementById('wetterChart')
          if (ctxContent != null) {
            const ctx = document.getElementById('wetterChart').getContext('2d')
            self.wetterChart = new Chart(ctx, {
              type: 'line',
              data: {
                datasets: [
                  {
                    label: 'Temperatur',
                    data: self.wetterDaten.tagesWerteTemperatur,
                    borderColor: 'red',
                    backgroundColor: 'red',
                    fill: false,
                    pointRadius: 0,
                    yAxisID: 'y',
                    tension: 0.5
                  },
                  {
                    label: 'Niederschlag',
                    data: self.wetterDaten.tagesWerteNiederschlag,
                    borderColor: '#037ffc',
                    backgroundColor: '#037ffc',
                    fill: true,
                    pointRadius: 0,
                    yAxisID: 'y1',
                    tension: 0.5
                  }
                ]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                  mode: 'index',
                  intersect: false
                },
                stacked: false,
                plugins: {
                  title: {
                    display: false,
                    text: 'Wetterdaten'
                  },
                  tooltip: {
                    enabled: true,
                    callbacks: {
                      title: function (tooltipItem) {
                        return moment(tooltipItem[0].parsed.x).format('DD.MM h') + ':00 Uhr'
                      }
                    }
                  },
                  pan: {
                    enabled: true,
                    mode: 'x',
                    speed: 10,
                    threshold: 10
                  },
                  zoom: {
                    enabled: true,
                    mode: 'y'
                  }
                },
                scales: {
                  x: {
                    type: 'time',
                    display: true,
                    title: {
                      display: true,
                      text: 'Datum'
                    },
                    time: {
                      unit: 'day'
                    },
                    grid: {
                      display: false
                    }
                  },
                  y: {
                    type: 'linear',
                    display: true,
                    title: {
                      display: true,
                      text: '°C'
                    },
                    position: 'left'
                  },
                  y1: {
                    type: 'linear',
                    display: true,
                    title: {
                      display: true,
                      text: 'mm'
                    },
                    max: 1.4,
                    position: 'right',
                    grid: {
                      drawOnChartArea: false
                    }
                  }
                }
              }
            })
            self.wetterChart.scales.x.ticks[0].label = 'Heute'
            self.wetterChart.update()
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
