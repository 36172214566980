<template>
  <el-container id="app">
    <div id="mainNav">
      <el-container v-if="$route.matched[0].path === ''">
        <el-header style="background-color: whitesmoke; height: 65px">
          <el-row type="flex" class="row-bg" justify="space-between">
            <el-col :span="6" style="text-align: start; align-self: center;"></el-col>
            <el-col :span="12">
              <el-row type="flex" justify="center">
                <el-image alt="Amagrar Logo" :src="require('./assets/amagrar_logo.png')"
                          style="height:50px; max-height: 50px; width:140px; max-width: 140px; margin-top: 5px"></el-image>
              </el-row>
            </el-col>
            <el-col :span="6" style="text-align: end; align-self: center;">
              <el-button v-if="loggedIn" @click="logMeOut = true" icon="el-icon-switch-button" class="logout-icon"
                         circle></el-button>
            </el-col>
          </el-row>
        </el-header>
      </el-container>
      <el-container v-else-if="$route.matched[0].path !== '' && showHeader">
        <el-header style="background-color: whitesmoke; height: 65px">
          <el-row type="flex" class="row-bg" justify="space-between">
            <el-col :span="6" style="text-align: start; align-self: center;"></el-col>
            <el-col :span="12">
              <el-row type="flex" justify="center">
                <el-image alt="Amagrar Logo" :src="require('./assets/amagrar_logo.png')"
                          style="height:50px; max-height: 50px; width:130px; max-width: 130px; margin-top: 5px"></el-image>
              </el-row>
            </el-col>
            <el-col :span="6" style="text-align: end; align-self: center;">
              <el-button v-if="loggedIn" @click="logMeOut = true" icon="el-icon-switch-button" class="logout-icon"
                         circle></el-button>
            </el-col>
          </el-row>
        </el-header>
      </el-container>
      <el-card v-if="loggedIn && $route.matched[0].path !== '' || $route.matched[0].path === '/about'" class="subNav">
        <el-page-header @back="goBack" content="detail" style="align-items: center;">
          <div slot="title"><b>Zurück</b></div>
          <div slot="content">
            <el-row v-if="$route.matched[0].path !== '/about'">
              <div style="display: flex; align-items: center; justify-content: space-between">
                <b>{{ $store.state.routeName }}</b>
                <el-image v-if="!showHeader" alt="Amagrar Logo" :src="require('./assets/amagrar_logo.png')"
                          style="height:50px; max-height: 50px; width:130px; max-width: 130px;"></el-image>
                <i v-if="!showHeader" class="nav-icon-refresh el-icon-switch-button" style="color: red;"
                   @click="logMeOut = true"></i>
                <i v-else class="nav-icon-refresh el-icon-refresh" style="padding-right: 10px;"
                   @click="refreshPage"></i>
              </div>
            </el-row>
          </div>
        </el-page-header>
      </el-card>
    </div>
    <el-dialog
      v-if="!$store.state.submitFailed"
      title="Achtung"
      :visible.sync="foundFormData"
      width="90%"
      center>
      <p style="text-align: center; word-break: break-word;">Soll die aktuelle Rückmeldung wirklich abgebrochen
        werden?</p>
      <div></div>
      <span slot="footer" class="dialog-footer">
    <el-button type="danger" @click="deleteFormData">Ja</el-button>
    <el-button type="primary" @click="foundFormData = false">Nein</el-button>
  </span>
    </el-dialog>
    <el-dialog
      v-else-if="$store.state.submitFailed"
      title="Achtung"
      :visible.sync="foundFormData"
      width="90%"
      center>
      <p style="text-align: center; word-break: break-word;">Soll diese Rückmeldung gelöscht oder zu einem späteren
        Zeitpunkt verschickt werden?</p>
      <span slot="footer" class="dialog-footer">
    <el-button type="danger" @click="deleteFormData">Löschen</el-button>
    <el-button type="info" @click="sendLater">Später senden!</el-button>
  </span>
    </el-dialog>
    <el-main class="main-content" :style="{paddingTop: mainContentPadding}">
      <template>
        <section>
          <el-dialog
            title="Achtung"
            :visible.sync="logMeOut"
            width="90%"
            center>
            <p style="text-align: center; word-break: break-word;">Möchten Sie sich wirklich ausloggen?</p>
            <div v-if="foundUnsavedFormData">
              <el-divider></el-divider>
              <p style="text-align: center; word-break: break-word;">Rückmeldungen die noch nicht gesendet wurden,
                werden gelöscht!</p>
              <el-divider></el-divider>
            </div>
            <span slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="logout(true)">Ja</el-button>
                  <el-button type="danger" @click="logMeOut = false">Nein</el-button>
                </span>
          </el-dialog>
          <router-view
            @updateGlobalData="updateGlobalData">
          </router-view>
        </section>
      </template>
    </el-main>
    <el-footer height="100">
      <el-divider></el-divider>
      <p>
        <router-link v-if="$route.matched[0].path !== '/about'" to="/about">Impressum</router-link>
      </p>
    </el-footer>
  </el-container>
</template>

<script>
import auth from './auth'

export default {
  data () {
    return {
      loggedIn: false,
      isFixed: true,
      mainContentPadding: this.setPadding(),
      logMeOut: false,
      displayWidth: window.innerWidth,
      displayHeight: window.innerHeight,
      showHeader: false,
      foundUnsavedFormData: false
    }
  },
  created () {
    this.loggedIn = auth.loggedIn()
    window.addEventListener('resize', this.changeHeader)
    window.addEventListener('orientationchange', this.changeHeader, false)
  },
  destroyed () {
    window.removeEventListener('resize', this.changeHeader)
    window.removeEventListener('orientationchange', this.changeHeader, false)
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (content) {
        if (content) {
          this.loggedIn = auth.loggedIn()
          this.mainContentPadding = this.setPadding()
        }
      }
    },
    displayWidth: {
      immediate: true,
      handler: function (width) {
        this.showHeader = width < window.innerHeight
      }
    },
    displayHeight: {
      immediate: true,
      handler: function (height) {
        this.showHeader = height > window.innerWidth
      }
    },
    logMeOut: {
      immediate: true,
      handler: function (logOut) {
        if (logOut) {
          const unsavedFormData = JSON.parse(localStorage.unsavedFormData)
          this.foundUnsavedFormData = unsavedFormData.length > 0 ?? false
        }
      }
    }
  },
  methods: {
    changeHeader (event) {
      const width = event.target.innerWidth
      const height = event.target.innerHeight
      this.showHeader = width <= height
    },
    logout (e) {
      if (e) {
        auth.logout()
        this.loggedIn = false
        this.logMeOut = !this.logMeOut
        this.$router.push('/login')
      }
    },
    goBack () {
      if (this.$store.state.deepNav) {
        if (localStorage.formData) {
          const activeFormData = JSON.parse(localStorage.formData)
          if (activeFormData) {
            this.foundFormData = true
          }
        } else {
          console.log('kultur', this.$store.state.kultur)
          console.log('navigation', this.$store.state.navigation)
          this.$router.push({
            name: 'Parzellen',
            params: {
              kultur: this.$store.state.kultur,
              navigation: this.$store.state.navigation
            }
          })
        }
      } else {
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
      }
    },
    sendLater () {
      if (!this.$store.state.formError) {
        const formData = JSON.parse(localStorage.formData)
        let unsavedFormData = JSON.parse(localStorage.unsavedFormData)
        let found = false
        for (let i = 0; i < unsavedFormData.length; i++) {
          if (JSON.stringify(formData) === JSON.stringify(unsavedFormData[i].data)) {
            found = true
          }
        }
        if (!found) {
          unsavedFormData.push({
            data: JSON.parse(localStorage.formData),
            date: Date.now(),
            parzellen: []
          })
          unsavedFormData = JSON.stringify(unsavedFormData)
          localStorage.unsavedFormData = unsavedFormData
        }
      }
      localStorage.removeItem('formData')
      localStorage.removeItem('rawFormData')
      this.foundFormData = false
      this.$store.commit('submitFailed', false)
    },
    deleteFormData () {
      if (this.$store.state.submitFailed) {
        const formData = JSON.parse(localStorage.formData)
        const unsavedFormData = JSON.parse(localStorage.unsavedFormData)
        for (let i = 0; i < unsavedFormData.length; i++) {
          if (JSON.stringify(formData) === JSON.stringify(unsavedFormData[i].data)) {
            unsavedFormData.splice(unsavedFormData[i], 1)
          }
        }
        localStorage.unsavedFormData = JSON.stringify(unsavedFormData)
      }
      localStorage.removeItem('formData')
      localStorage.removeItem('rawFormData')
      this.foundFormData = false
      this.$store.commit('submitFailed', false)
      this.goBack()
    },
    setPadding () {
      let padding = 0
      const noLoginNeeded = this.$route.matched[0].path === '/about'
      if (this.displayWidth < this.displayHeight) {
        padding = (this.loggedIn && this.$route.matched[0].path !== '') || noLoginNeeded ? '140px' : '70px'
      } else {
        padding = (this.loggedIn && this.$route.matched[0].path !== '') || noLoginNeeded ? '95px' : '70px'
      }
      return padding
    },
    refreshPage (state) {
      const loading = this.$loading({
        lock: true,
        text: 'Lade Daten',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      setTimeout(() => {
        console.log(state)
        // this.$router.go()
        loading.close()
      }, 2000)
    }
  }
}
</script>

<style>
.el-select-dropdown__item {
  opacity: 1;
}

@media (hover: hover) {
  .el-button:hover {
    color: inherit;
  }

  .el-select-dropdown__item:hover {
    background-color: inherit;
  }
}

.el-scrollbar > .el-scrollbar__bar {
  opacity: 1;
}

@media (hover: none) {
  .el-button:active {
    color: inherit;
  }

  .el-select-dropdown__item:active {
    background-color: transparent;
  }
}

.el-page-header__content {
  width: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#mainNav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 200;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}

.subNav {
  background-color: whitesmoke;
}

.nav-icon-refresh {
  font-size: 20px;
}

.logout-icon {
  font-size: 1.25rem;
  border: none;
  color: red;
  background-color: whitesmoke;
}
</style>
