<template>
  <el-form @submit.prevent="validateFormData" ref="elForm">
    <el-row type="flex" justify="center">
      <el-col :xs="Number(24)" :md="Number(12)" :lg="Number(12)"
              :xl="Number(6)">
        <el-card v-if="parzelle || modalCreate">
          <div v-if="parzellenNamen.length <= 1" slot="header">
            <p><b>Rückmeldung für die Parzelle:</b></p>
            <el-tag size="small" style="margin-right: 5px"
                    v-for="parzelle in parzellenNamen" :key="parzelle.name">
              {{ parzelle.name }}
            </el-tag>
          </div>
          <div v-else slot="header">
            <p><b>Rückmeldung für die Parzellen:</b></p>
            <el-tag size="small" style="margin-right: 5px"
                    v-for="parzelle in parzellenNamen" :key="parzelle.name">
              {{ parzelle.name }}
            </el-tag>
          </div>
          <div style="padding-bottom: 20px;">
            <el-alert v-if="parzellenNamen.length > 1"
                      title="Bitte beachten!"
                      type="info"
                      description="Bei der Auswahl von mehreren Parzellen gleichzeitig, werden die allgemeinen Rückmeldungen über den Status der Parzellen auf alle Parzellen angewendet!"
                      show-icon>
            </el-alert>
          </div>
          <el-collapse v-model="activeSection" accordion>
            <div v-for="baseForm in baseForms"
                 :key="baseForm.name">
              <el-collapse-item
                v-if="baseForm.first || baseForm.formOrder.length > 0 && baseForm.formOrder[0][0].valid"
                :title="baseForm.name"
                :name="baseForm.id">
                <template slot="title">
                  <el-row type="flex" justify="space-between"
                          style="width: 100%; align-self: baseline; align-items: baseline; padding-top: 3px;">
                    <el-col :span="6"><b>{{ baseForm.name }}</b></el-col>
                    <el-col :span="12" style="margin:-4px 8px 0 0; text-align: end;">
                      <i v-if="formDataErrors[baseForm.id].notValid && !formDataErrors[baseForm.id].isReady"
                         class="el-icon-close" style="color:red; font-size:20px; font-weight:bold;"></i>
                      <i v-else-if="!formDataErrors[baseForm.id].notValid && !formDataErrors[baseForm.id].isReady"
                         class="el-icon-check" style="color:green; font-size:20px; font-weight:bold;"></i>
                      <i v-else-if="!formDataErrors[baseForm.id].notValid && formDataErrors[baseForm.id].isReady"
                         class="el-icon-check" style="color:grey; font-size:20px; font-weight:bold;"></i>
                    </el-col>
                  </el-row>
                </template>
                <component
                  :base-form="baseForm"
                  :base-bundle="baseBundle"
                  :base-eigenschaften="baseEigenschaften"
                  :base-array="setArray(baseForm.formBranch + 'Array')"
                  :parzelle="parzelle"
                  :id-kultur="baseForm.idKultur"
                  :id-parzelle="baseForm.idParzelle"
                  :id-parzelle-fruchtfolge="baseForm.idParzelleFruchtfolge"
                  :psm-types="pflanzenschutzmittelTypes"
                  @setFormData="setFormData"
                  @emitFormData="removeFormData"
                  @updateGlobalData="updateGlobalData"
                  @newSwitchState="updateNewSwitchState"
                  @newErrorState="newErrorState"
                  @setNewFormDataErrors="setNewFormDataErrors"
                  v-bind:is="baseForm.formBranch + '-' + baseForm.formTree"/>
              </el-collapse-item>
            </div>
          </el-collapse>
          <el-divider></el-divider>
          <p>
            <el-alert v-show="formError" type="error" :closable="false">Es sind noch nicht alle Pflichtfelder bearbeitet
              worden!
            </el-alert>
          </p>
          <el-button :disabled="formError" type="success" size="large" @click="validateFormData">Rückmeldung Senden
          </el-button>
        </el-card>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import parzelle from '../api/parzelle'
import FormComponent from '../components/forms/form-component'
import KulturRueckmeldung from '../components/rueckmeldungen/kultur-rueckmeldung'
import PflanzenschutzRueckmeldung from '../components/rueckmeldungen/pflanzenschutz-rueckmeldung'
import BeregnungRueckmeldung from '../components/rueckmeldungen/beregnung-rueckmeldung'
import PflanzenkrankheitenRueckmledung from '../components/rueckmeldungen/pflanzenkrankheiten-rueckmledung'
import ResistenzenRueckmeldung from '../components/rueckmeldungen/resistenzen-rueckmeldung'
import rueckmeldung from '../api/rueckmeldung'
import FolienabnahmeRueckmeldung from '../components/rueckmeldungen/folienabnahme-rueckmeldung'
import NiederschlagRueckmeldung from '../components/rueckmeldungen/niederschlag-rueckmeldung'
import { formMethods } from '../mixins/form-methods'
import pflanzenschutzmittelType from '../api/pflanzenschutzmittel-type'
import GelbschaleRueckmeldung from '../components/rueckmeldungen/gelbschale-rueckmeldung'

export default {
  mixins: [formMethods],
  components: {
    'form-component': FormComponent,
    'kultur-rueckmeldung': KulturRueckmeldung,
    'pflanzenschutz-rueckmeldung': PflanzenschutzRueckmeldung,
    'beregnung-rueckmeldung': BeregnungRueckmeldung,
    'pflanzenkrankheiten-rueckmeldung': PflanzenkrankheitenRueckmledung,
    'resistenzen-rueckmeldung': ResistenzenRueckmeldung,
    'folienabnahme-rueckmeldung': FolienabnahmeRueckmeldung,
    'niederschlag-rueckmeldung': NiederschlagRueckmeldung,
    'gelbschale-rueckmeldung': GelbschaleRueckmeldung
  },
  props: {
    parzelle: [Array, Object],
    kultur: [Array, Object],
    navigation: [Array, Object],
    modalCreate: Boolean,
    modalData: Object
  },
  data () {
    return {
      submitState: true,
      valid: false,
      result: undefined,
      serverResponse: false,
      feedback: false,
      parzellenNamen: this.setParzellenNamen(),
      rueckmeldungsarten: null,
      pflanzenschutzmittel: null,
      pflanzenschutzmittelTypes: null,
      pflanzenkrankheiten: null,
      visible: {},
      activeSection: 1,
      disabled: false,
      baseEigenschaften: null,
      baseBundle: null,
      baseForms: null,
      kulturArray: null,
      pflanzenschutzArray: null,
      beregnungArray: null,
      pflanzenkrankheitenArray: null,
      resistenzenArray: null,
      bubble: 'red',
      feedbackResult: false,
      saatDatum: ''
    }
  },
  created () {
    if (!this.parzelle && !this.modalCreate) {
      this.$router.replace(this.$route.query.redirect || '/')
      this.formData = {}
    } else {
      if (!this.modalCreate) {
        this.initRueckmeldungsdata()
        this.setParzellenNamen(this.parzelle.Name)
      } else {
        this.initRueckmeldungsdata(this.modalData.parzelle, this.modalData.fruchtfolge, this.modalData.kultur, this.modalData.sorte)
      }
      this.$store.commit('changeRouteName', this.parzelle.Fruchtfolge[0].Kultur)
      this.$store.commit('setKultur', this.$store.state.kultur)
      this.$store.commit('setNavigation', this.$store.state.navigation)
      this.$store.commit('setDeepStateNavigation', true)
      this.initRueckmeldungsEigenschaften()
    }
  },
  watch: {
    result: {
      immediate: true,
      handler: function (result) {
        if (result) {
          this.feedbackResult = result
          delete localStorage.formData
          let type = ''
          let title = ''
          let message = ''
          if (this.feedbackResult) {
            type = 'success'
            title = 'Erfolg'
            message = 'Die Rückmeldung wurde erfolgreich angelegt!'
            this.$router.push({
              name: 'Parzellen',
              params: {
                kultur: this.$store.state.kultur,
                navigation: this.$store.state.navigation
              }
            })
          } else {
            this.serverResponse = true
            type = 'error'
            title = 'Fehler'
            message = 'Die Rückmeldung konnte nicht angelegt werden! Bitte versuchen Sie es erneut!'
          }
          this.notify(
            type,
            title,
            message,
            0,
            true
          )
          this.result = undefined
        } else {
          if (this.serverResponse) {
            this.$store.commit('submitFailed', this.serverResponse)
            let unsaved = JSON.parse(localStorage.unsavedFormData)
            let equals = false
            if (unsaved.length > 0) {
              const formDataStorage = JSON.parse(localStorage.formData)
              for (let i = 0; i < unsaved.length; i++) {
                if (JSON.stringify(formDataStorage) === JSON.stringify(unsaved[i].data)) {
                  unsaved[i].data = formDataStorage
                  unsaved[i].date = Date.now()
                  unsaved[i].parzellen = this.modalData ? this.modalData.parzellen : []
                  equals = true
                }
              }
              if (!equals) {
                unsaved.push({
                  data: JSON.parse(localStorage.formData),
                  date: Date.now(),
                  parzellen: this.modalData ? this.modalData.parzellen : []
                })
              }
            } else {
              unsaved.push({
                data: {},
                date: Date.now(),
                parzellen: this.modalData ? this.modalData.parzellen : []
              })
              unsaved[0].data = JSON.parse(localStorage.formData)
            }
            unsaved = JSON.stringify(unsaved)
            localStorage.unsavedFormData = unsaved
          }
        }
      }
    },
    baseForms: {
      immediate: true,
      handler: function (baseForms) {
        if (baseForms) {
          this.setNewRawFormData(baseForms)
        }
      }
    }
  },
  methods: {
    setNewRawFormData (baseForms) {
      const obj = {}
      const branches = {}
      for (let b = 0; b < baseForms.length; b++) {
        const idKultur = this.strToNum(baseForms[b].idKultur)
        const idParzelle = this.strToNum(baseForms[b].idParzelle)
        const idParzelleFruchtfolge = this.strToNum(baseForms[b].idParzelleFruchtfolge)
        const formTree = baseForms[b].formTree
        const formBranch = baseForms[b].formBranch
        const branch = { [formBranch]: [] }
        Object.assign(branches, branch)
        const baseObj = {
          [idKultur]: {
            [idParzelle]: {
              [idParzelleFruchtfolge]: {
                [formTree]: {}
              }
            }
          }
        }
        Object.assign(obj, baseObj)
        Object.assign(obj[idKultur][idParzelle][idParzelleFruchtfolge][formTree], branches)
      }
      localStorage.rawFormData = JSON.stringify(obj)
    },
    initRueckmeldungsdata (id, fruchtfolge, kultur, sorte) {
      const self = this
      const idBetrieb = JSON.parse(localStorage.user).ID_Betrieb
      const idRolle = JSON.parse(localStorage.user).ID_Rolle
      const idParzelle = id ?? this.parzelle.ID_Parzelle
      const idParzelleFruchtfolge = fruchtfolge ?? this.parzelle.Fruchtfolge[0].ID_Parzelle_Fruchtfolge
      const idKultur = kultur ?? this.parzelle.Fruchtfolge[0].ID_Kultur
      const idSorte = sorte ?? this.parzelle.Fruchtfolge[0].ID_Sorte
      parzelle.methods.getRueckmeldungsDaten({
        ID_Betrieb: idBetrieb,
        ID_Rolle: idRolle,
        ID_Parzelle: idParzelle,
        ID_Parzelle_Fruchtfolge: idParzelleFruchtfolge,
        ID_Kultur: idKultur,
        ID_Sorte: idSorte
      }).then(response => {
        console.log(response.data[0])
        self.rueckmeldungsarten = response.data[0].kultur_rueckmeldungsarten
        self.pflanzenschutzmittel = response.data[0].pflanzenschutzmittel
        self.handleGetAllPflanzenschutzmittelTypes()
        self.pflanzenkrankheiten = response.data[0].pflanzenkrankheiten
        self.baseBundle = response.data[0].baseBundle
        self.baseForms = response.data[0].baseForms
        self.pflanzenschutzArray = response.data[0].pflanzenschutzArray
        self.pflanzenkrankheitenArray = response.data[0].pflanzenkrankheitenArray
        self.beregnungArray = response.data[0].beregnungArray
        self.resistenzenArray = response.data[0].resistenzenArray
        self.formDataErrors = self.setFormDataErrors(response.data[0].baseBundle)
        self.saatDatum = response.data.saatDatum
      })
    },
    handleGetAllPflanzenschutzmittelTypes () {
      const self = this
      pflanzenschutzmittelType.methods.getAllPflanzenschutzmittelTypes({
        Name: '',
        ID_Kultur: this.parzelle.Fruchtfolge[0].ID_Kultur
      }).then(response => {
        self.pflanzenschutzmittelTypes = response.data.psmTypes
      })
    },
    setFormDataErrors (baseBundle) {
      const obj = {}
      const psm = this.pflanzenschutzmittel
      for (let i = 0; i < psm.length; i++) {
        const num = 10101010 + i
        this.baseBundle.push({
          formId: 2,
          bundleId: num.toString()
        })
      }
      for (let i = 0; i < baseBundle.length; i++) {
        const baseObj = {
          [baseBundle[i].bundleId]: {
            value: '',
            msg: '',
            notValid: false,
            isReady: false
          }
        }
        Object.assign(obj, baseObj)
      }
      for (let i = 0; i < this.baseForms.length; i++) {
        const baseObj = {
          [this.baseForms[i].id]: {
            value: '',
            msg: '',
            notValid: false,
            isReady: false
          }
        }
        Object.assign(obj, baseObj)
      }
      return obj
    },
    initRueckmeldungsEigenschaften () {
      const self = this
      parzelle.methods.getAlleRueckmeldungsEigenschaften().then(response => {
        self.baseEigenschaften = response.data.eigenschaften
      })
    },
    setParzellenNamen (name) {
      const parzellenNamen = []
      if (this.parzelle && name) {
        this.parzellenNamen.push({
          name: name
        })
      } else if (this.modalData) {
        for (let i = 0; i < this.modalData.namen.length; i++) {
          parzellenNamen.push({
            name: this.modalData.namen[i]
          })
        }
      }
      return parzellenNamen
    },
    validateFormData (section) {
      this.formDataChanged()
      const formData = JSON.parse(localStorage.formData)
      const modalData = this.modalData
      const validation = Object.keys(this.formDataErrors).filter(key => this.formDataErrors[key].notValid === true)
      if (formData && validation.length <= 0) {
        const data = JSON.parse(localStorage.formData)
        const parzellen = []
        if (modalData && modalData.parzellen.length > 1) {
          for (let i = 0; i < modalData.parzellen.length; i++) {
            parzellen.push({
              idParzelle: modalData.parzellen[i].Fruchtfolge[0].ID_Parzelle,
              idFruchtfolge: modalData.parzellen[i].Fruchtfolge[0].ID_Parzelle_Fruchtfolge
            })
          }
          this.submit(data, parzellen)
        } else {
          this.submit(data)
        }
      }
    },
    submit (data, parzellen) {
      const self = this
      const idBenutzer = JSON.parse(localStorage.user).ID_Benutzer
      const idKultur = Object.keys(data)[0]
      const idParzelle = Object.keys(data[idKultur])[0]
      const idParzelleFruchtfolge = Object.keys(data[idKultur][idParzelle])[0]
      const parzelle = []
      parzelle.push({
        idParzelle: idParzelle,
        idFruchtfolge: idParzelleFruchtfolge
      })
      const parzellenArray = parzellen || parzelle
      const h = this.$createElement
      this.$msgbox({
        title: '',
        message: h('p', null, [
          h('span', null, ' Soll die Rückmeldung jetzt gesendet werden?')
        ]),
        customClass: 'submit-box',
        showCancelButton: true,
        confirmButtonText: 'Senden',
        cancelButtonText: 'Abbrechen',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = 'Loading...'
            setTimeout(() => {
              done()
              setTimeout(() => {
                rueckmeldung.methods.submitRueckmeldung({
                  idBenutzer: idBenutzer,
                  idKultur: idKultur,
                  idParzelle: idParzelle,
                  idParzelleFruchtfolge: idParzelleFruchtfolge,
                  parzellen: parzellenArray,
                  kulturRueckmeldung: data[idKultur][idParzelle][idParzelleFruchtfolge].rueckmeldung.kultur,
                  pflanzenschutz: data[idKultur][idParzelle][idParzelleFruchtfolge].rueckmeldung.pflanzenschutz,
                  beregnung: data[idKultur][idParzelle][idParzelleFruchtfolge].rueckmeldung.beregnung,
                  pflanzenkrankheiten: data[idKultur][idParzelle][idParzelleFruchtfolge].rueckmeldung.pflanzenkrankheiten,
                  resistenzen: data[idKultur][idParzelle][idParzelleFruchtfolge].rueckmeldung.resistenzen,
                  folienabnahme: data[idKultur][idParzelle][idParzelleFruchtfolge].rueckmeldung.folienabnahme,
                  niederschlag: data[idKultur][idParzelle][idParzelleFruchtfolge].rueckmeldung.niederschlag,
                  rueckmeldungen: data[idKultur][idParzelle][idParzelleFruchtfolge].rueckmeldung
                }).then(response => {
                  self.result = response.data.results ?? false
                  instance.confirmButtonLoading = false
                })
              }, 300)
            }, 3000)
          } else {
            done()
          }
        }
      })
    }
  }
}
</script>
<style>
.submit-box {
  width: 90%;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}
</style>
