<template>
  <section>
    <el-col
      v-if="baseForm.formOrder.length > 0"
      :span="6"
      :xs="24">
      <div class="grid-content">
        <el-card>
          <component
            :cdzForm="setBundle(baseForm.formOrder[0][0].id, baseForm.formOrder[0][0].fieldId)"
            :form-main-attributes="baseBundle"
            @changedSwitchState="newSwitchState"
            :form-tree="baseForm.formTree"
            :form-branch="baseForm.formBranch"
            :id-kultur="baseForm.idKultur"
            :id-parzelle="baseForm.idParzelle"
            :id-parzelle-fruchtfolge="baseForm.idParzelleFruchtfolge"
            v-bind:is="baseForm.formOrder[0][0].component"/>
        </el-card>
      </div>
      <div v-if="visible.resistenzen">
    <el-row v-for="(bundle) in baseBundle" :key="bundle.bundleId">
      <div v-if="baseForm.id === bundle.formId">
        <form-component
          :cdzForm="bundle"
          :form-base-bundle="baseBundle"
          :form-base-title="baseForm.name"
          :form-name="bundle.formName"
          :form-field-id="bundle.rmaId"
          :form-attributes="baseEigenschaften"
          :form-array="baseArray"
          :form-big-build="bundle.firstOrder"
          :form-tree="baseForm.formTree"
          :form-branch="baseForm.formBranch"
          :id-kultur="baseForm.idKultur"
          :id-parzelle="baseForm.idParzelle"
          :id-parzelle-fruchtfolge="baseForm.idParzelleFruchtfolge"
          @setFormData="getFormData"
          @updateGlobalData="updateGlobalData"
          @emitFormData="emitFormData">
        </form-component>
      </div>
    </el-row>
      </div>
    </el-col>
    <el-col v-else>
      <el-card>
            <el-alert type="warning" :closable="false"><b>Für diese Option stehen noch keine Daten zur Verfügung!</b>
            </el-alert>
      </el-card>
    </el-col>
  </section>
</template>

<script>

import FormComponent from '../forms/form-component'
export default {
  name: 'resistenzen-rueckmeldung',
  components: { FormComponent },
  props: {
    baseForm: Object,
    baseBundle: Array,
    baseArray: Array,
    baseEigenschaften: Array
  },
  data () {
    return {
      visible: {
        resistenzen: false
      }
    }
  }
}
</script>

<style scoped>
</style>
