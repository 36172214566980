import { encode } from 'querystring-es3'
const axios = require('axios')

export default {
  methods: {
    getInfektionsRisikoDaten (query) {
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/Infektionsrisiko/getInfektionsRisikoDaten', query)
    },
    getInfektionsrisiko (query) {
      console.log('query', query)
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/Infektionsrisiko/getInfektionsrisiko/pwaCallInfektionsrisiko', query)
    },
    getRestschutz (query) {
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/Infektionsrisiko/getRestschutz', query)
    }
  }
}
