import { encode } from 'querystring-es3'
const axios = require('axios')

export default {
  data: function () {
    return {
      user: []
    }
  },
  methods: {
    signIn (email, pass) {
      return axios.post(axios.defaults.baseURL + '/api/user/login', encode({
        Email: email,
        Passwort: pass
      }))
    }
  }
}
