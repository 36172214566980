<template>
  <section>
    <el-col
      v-if="baseForm.formOrder.length > 0 && baseForm.formOrder[0][0].valid"
      :span="6"
      :xs="24">
      <div class="grid-content">
        <el-card>
          <component
            :cdzForm="setBundle(baseForm.formOrder[0][0].id, baseForm.formOrder[0][0].fieldId)"
            :form-main-attributes="baseBundle"
            @changedSwitchState="newSwitchState"
            :form-tree="baseForm.formTree"
            :form-branch="baseForm.formBranch"
            :id-kultur="baseForm.idKultur"
            :id-parzelle="baseForm.idParzelle"
            :id-parzelle-fruchtfolge="baseForm.idParzelleFruchtfolge"
            v-bind:is="baseForm.formOrder[0][0].component"/>
        </el-card>
      </div>
      <div v-if="visible.gelbschale">
        <el-card>
          <div slot="header">
            <div>
              <el-alert v-show="!date.value" type="warning" :closable="false"><b>Bitte zuerst das Datum festlegen!</b>
              </el-alert>
              <span v-show="date.value"><b>Datum</b></span>
            </div>
          </div>
          <component
            :cdzForm="setBundle(baseForm.formOrder[1][0].id, baseForm.formOrder[1][0].fieldId)"
            :form-main-attributes="baseBundle"
            @setFormDate="setFormDate"
            v-bind:is="baseForm.formOrder[1][0].component"/>
        </el-card>
        <el-card v-if="date.value">
          <span><b>Gelbschale</b></span>
          <el-divider></el-divider>
          <div v-for="(formField, key) in baseForm.formOrder" :key="formField[0].id">
            <el-row v-for="(bundle) in baseBundle" :key="bundle.bundleId">
              <div v-if="baseForm.id === bundle.formId && formField[0].fieldId === bundle.id">
                <form-component
                  v-if="key === 2"
                  :cdzForm="bundle"
                  :form-base-bundle="baseBundle"
                  :form-base-title="baseForm.name"
                  :form-name="bundle.formName"
                  :form-field-id="bundle.rmaId"
                  :form-attributes="baseEigenschaften"
                  :form-array="baseArray"
                  :form-big-build="bundle.firstOrder"
                  :form-tree="baseForm.formTree"
                  :form-branch="baseForm.formBranch"
                  :id-kultur="baseForm.idKultur"
                  :id-parzelle="baseForm.idParzelle"
                  :id-parzelle-fruchtfolge="baseForm.idParzelleFruchtfolge"
                  @setFormData="getFormData"
                  @updateGlobalData="updateGlobalData"
                  @emitFormData="emitFormData">
                </form-component>
              </div>
            </el-row>
          </div>
        </el-card>
      </div>
    </el-col>
    <el-col v-else>
      <el-card>
        <el-alert type="warning" :closable="false"><b>Für diese Option stehen noch keine Daten zur Verfügung!</b>
        </el-alert>
      </el-card>
    </el-col>
  </section>
</template>

<script>

import FormSwitchField from '../forms/switchs/form-switch-field'
import FormInputDateField from '../forms/inputs/form-input-date-field'
import FormSelectDynamic from '../forms/selects/form-select-dynamic'
import { formMethods } from '../../mixins/form-methods'
import FormComponent from '../forms/form-component'

export default {
  name: 'gelbschale-rueckmeldung',
  mixins: [formMethods],
  components: {
    'form-switch-field': FormSwitchField,
    'form-date-field': FormInputDateField,
    'form-select-dynamic': FormSelectDynamic,
    'form-component': FormComponent
  },
  props: {
    baseForm: Object,
    baseBundle: Array,
    baseArray: Array,
    baseEigenschaften: Array
  },
  data () {
    return {
      gelbschale: true,
      visible: {
        gelbschale: false
      }
    }
  },
  watch: {
    date: {
      immediate: true,
      handler: function (date) {
        if (date.value !== '' && this.visible && this.gelbschale) {
          // TO-DO
          const baseForm = this.baseForm
          const formData = JSON.parse(localStorage.formData)[baseForm.idKultur][baseForm.idParzelle][baseForm.idParzelleFruchtfolge][baseForm.formTree][baseForm.formBranch]
          console.log(formData)
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
