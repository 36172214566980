import { encode } from 'querystring-es3'
const axios = require('axios')

export default {
  methods: {
    getWetterstationenVonBetrieb (query) {
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/Wetterstation/getWetterstationenVonBetrieb/pwaCallWetterStationVonBetrieb', query)
    },
    getWetterstationFromParzelle (query) {
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/Wetterstation/getWetterstationFromParzelle/pwaCallWetterstationFuerParzelle', query)
    },
    getWetterdaten (query) {
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/wetterstation/getWetterdaten/pwaCallWetterDaten', query)
    },
    getWetterDatenFuerWetterstationVonBis (query) {
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/wetterstation/getWetterDatenFuerWetterstationVonBis', query)
    },
    getSpritzWetter (query) {
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/Wetterstation/ajaxGetSpritzWetter/pwaCallSpritzWetter', query)
    }
  }
}
