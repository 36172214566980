<template>
  <section>
    <el-form :model="cdzForm" :ref="cdzForm.name"></el-form>
    <el-form-item
      :inline-message="true"
      v-if="elOptionGroup" :error="errorMessage" :prop="cdzForm.bundleId">
      <el-select
        v-model="value"
        placeholder="Bitte wählen"
        @change="handleChange"
        width="80%"
        clearable
        popper-class="custom-select-group">
        <el-option-group
          v-for="group in options"
          :key="group.value"
          :value="group.value"
          :selected="group.selected">
          <el-divider>{{ group.name }} - {{ group.value }}</el-divider>
          <el-option
            :key="group.key"
            :label="group.value"
            :value="group.value"
            style="white-space: break-spaces; height: fit-content; text-align: -webkit-center;">
            <span>{{ group.label }}</span>
          </el-option>
        </el-option-group>
      </el-select>
    </el-form-item>
    <el-form-item
      v-else
      :inline-message="true"
      :error="errorMessage" :prop="cdzForm.bundleId">
      <el-select
        v-model="value"
        placeholder="Bitte wählen"
        @change="handleChange"
        width="80%">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
          :selected="item.selected">
        </el-option>
      </el-select>
    </el-form-item>
    <el-dialog title="BBCH-Status"
               :visible.sync="dialogFormVisible"
               width="90vw"
               :-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false"
               :before-close="dontClose">
      <el-alert
        title="Bitte bestätigen oder wählen!"
        type="warning"
        description="Wie ist der aktuelle BBCH-Status?"
        show-icon :closable="false">
      </el-alert>
      <el-divider></el-divider>
      <el-select
        v-model="value"
        placeholder="Bitte wählen"
        @change="handleChange"
        width="80%"
        clearable
        popper-class="custom-select-group">
        <el-option-group
          v-for="group in options"
          :key="group.value"
          :value="group.value"
          :selected="group.selected">
          <el-divider>{{ group.name }} - {{ group.value }}</el-divider>
          <el-option
            :key="group.key"
            :label="group.value"
            :value="group.value"
            style="white-space: break-spaces; height: fit-content; text-align: -webkit-center;">
            <span>{{ group.label }}</span>
          </el-option>
        </el-option-group>
      </el-select>
      <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="setValue">Bestätigen</el-button>
  </span>
    </el-dialog>
  </section>
</template>

<script>

// import Swal from 'sweetalert2'

export default {
  name: 'FormSelectSingle',
  props: {
    baseBundle: Array,
    formField: {
      type: Object,
      default () {
        return {
          formType: 'Select',
          formName: '',
          options: [{
            value: 'Option',
            label: 'Option',
            disabled: false
          }],
          psm: [],
          rawOptions: ''
        }
      }
    },
    resetSelect: Boolean,
    isType: [String, Number],
    changedFormField: Array
  },
  data () {
    return {
      elForm: {},
      formType: this.formField.formType ?? 'Select',
      options: this.cdzForm.name === 'Bestandesdichte' ? this.$store.state.bestandesdichte : [],
      bestandesdichte: [],
      value: '',
      newValue: '',
      disabled: false,
      elOptionGroup: false,
      errorMessage: '',
      dialogFormVisible: false,
      unsavedBBCH: {
        id: 0,
        baseId: 0,
        baseBundle: 0,
        status: true
      },
      updateFormField: []
    }
  },
  watch: {
    options: {
      deep: true,
      handler: function (data) {
        if (data.length > 0) {
          this.options = data
        }
        console.log('data', data)
      }
    },
    isType: {
      immediate: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.value = ''
        }
      }
    },
    resetSelect: {
      immediate: true,
      handler: function (bool) {
        if (bool && this.elForm[this.cdzForm.bundleId]) {
          this.$emit('resetBool', false)
        }
      }
    },
    value: {
      deep: true,
      handler (newVal, oldVal) {
        const elForm = this.elForm[this.cdzForm.bundleId]
        elForm.value = newVal
        this.newValue = newVal
        this.value = newVal
        if (oldVal !== '') {
          this.$emit('resetOptions', oldVal)
        } else {
          let data = this.formField.psm
          this.$nextTick(function () {
            data = this.formField.psm
          })
          this.$emit('updateFormField', data)
        }
      }
    },
    elForm: {
      deep: true,
      handler: function (elForm) {
        const id = this.cdzForm.bundleId
        console.log('id', id)
        const baseId = this.cdzForm.formId
        const min = this.cdzForm.min !== '' ? this.cdzForm.min : null
        const val = this.value
        const baseBundle = this.baseBundle
        if (val === '') {
          elForm[id].msg = 'Das ist ein Pflichtfeld!'
          elForm[id].notValid = true
        } else {
          if (min && val <= min && this.cdzForm.name === 'BBCH' && this.unsavedBBCH.status) {
            elForm[id].msg = 'Hat sich dieser Wert verändert?'
            elForm[id].notValid = true
            this.dialogFormVisible = true
            this.unsavedBBCH.id = id
            this.unsavedBBCH.baseId = baseId
            this.unsavedBBCH.baseBundle = baseBundle
          } else {
            elForm[id].msg = ''
            elForm[id].notValid = false
          }
        }
        if (this.cdzForm.name === 'BBCH') {
          this.$store.commit('setBBCH', this.value)
          this.$emit('bbchChanged', this.$store.state.bestandesdichte, this.$store.state.bbch)
        }
        if (this.cdzForm.name === 'Bestandesdichte') {
          const currentEC = this.$store.state.bbch
          if (currentEC !== this.$store.state.oldBBCH) {
            this.$store.commit('setOldBBCH', currentEC)
            this.value = ''
            elForm[id].notValid = true
            elForm[id].msg = 'Das ist ein Pflichtfeld!'
          }
          // this.$store.commit('bestandesdichte', this.options)
          // let options = this.options
          //
          // this.$nextTick(function () {
          //   this.options = options
          // })
          this.$store.commit('bestandesdichte', this.options)
          this.$store.commit('bestandesdichteBundleId', this.cdzForm.bundleId)
        }
        this.elForm = elForm
        this.formDataErrors[id] = elForm[id]
        this.setErrorMessage(elForm)
        this.$emit('newErrorState', elForm, baseId, baseBundle)
      }
    },
    cdzForm: {
      immediate: true,
      handler: function (cdzForm) {
        if (cdzForm.bundleId) {
          this.elFormData()
        }
        if (!this.formField.psm) {
          this.submitChange('', this.formFieldId)
        }
        if (Array.isArray(cdzForm.value)) {
          this.options = this.buildOptionsArray(false, cdzForm.value)
          this.elOptionGroup = true
        } else {
          this.options = this.buildOptionsArray()
          this.elOptionGroup = false
        }
      }
    },
    changedFormField: {
      deep: true,
      handler: function (data) {
        if (data) {
          data = data.filter(item => item.visible !== false)
          if (Array.isArray(data) && data.length > 0) {
            this.options = this.buildOptionsArray(data)
          }
        }
      }
    },
    formField: {
      immediate: true,
      handler: function (data) {
        if (data.psm) {
          data = data.psm.filter(item => item.visible !== false)
          if (Array.isArray(data) && data.length > 0) {
            this.options = this.buildOptionsArray(data)
          }
        }
      }
    },
    $props: {
      immediate: true,
      handler: function (data) {
        data = {
          kid: data.idKultur,
          pid: data.idParzelle,
          pfid: data.idParzelleFruchtfolge,
          ft: data.formTree,
          fb: data.formBranch
        }
        this.$emit('updateGlobalData', data)
      }
    }
  },
  methods: {
    setValue () {
      this.value = this.newValue
      this.handleChange(this.value)
      this.elForm[this.unsavedBBCH.id].notValid = false
      this.elForm[this.unsavedBBCH.id].msg = ''
      this.unsavedBBCH.status = false
      this.formDataErrors[this.unsavedBBCH.id] = this.elForm[this.unsavedBBCH.id]
      this.setErrorMessage(this.elForm)
      this.$emit('newErrorState', this.elForm, this.unsavedBBCH.baseId, this.unsavedBBCH.baseBundle)
      this.dialogFormVisible = false
    },
    dontClose () {
    },
    elFormData () {
      this.value = Array.isArray(this.cdzForm.value) ? this.cdzForm.min : this.value
      this.elForm = Object.assign({}, this.elForm, {
        [[this.cdzForm.bundleId]]: {
          value: this.value,
          msg: '',
          valid: undefined,
          isReady: false
        }
      })
      this.setErrorMessage(this.elForm)
    },
    setErrorMessage (data = false) {
      this.errorMessage = data ? data[this.cdzForm.bundleId].msg : ''
    },
    handleChange (value) {
      if (this.formField.psm.length > 0) {
        if (value !== '') {
          for (let i = 0; i < this.formField.psm.length; i++) {
            if (this.formField.psm[i].id === value) {
              this.$emit('changeSelect', i)
            }
          }
        } else {
          const data = this.formField.psm
          this.$emit('updateFormField', data)
        }
      } else if (this.formFieldId > 0) {
        this.submitChange(value, this.formFieldId)
      } else {
        this.submitChange(value)
      }
    },
    submitChange (value, fid = 0) {
      const id = this.cdzForm.id
      const pId = this.cdzForm.pkId
      const rmaId = this.cdzForm.rmaId
      let key = this.cdzForm.bundleId
      key = key ?? this.formFieldId
      if (fid > 0) {
        this.$emit('setNewFormData', {
          id: this.strToNum(id),
          pId: pId,
          rmaId: rmaId,
          key: key,
          value: value,
          formTree: this.formTree,
          formBranch: this.formBranch
        })
      } else {
        this.$emit('setNewFormData', {
          id: this.strToNum(id),
          pId: pId,
          rmaId: rmaId,
          key: key,
          value: value,
          formTree: this.formTree,
          formBranch: this.formBranch
        })
      }
    },
    buildOptionsArray (data, dbData) {
      let options = [{}]
      let empty = false
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          options[i] = {
            value: data[i].id,
            label: data[i].name,
            key: i
          }
        }
      } else if (dbData && dbData.length > 0) {
        for (let i = 0; i < dbData.length; i++) {
          options[i] = {
            value: dbData[i].value,
            label: dbData[i].label,
            name: this.cdzForm.name,
            key: i,
            selected: this.strToNum(dbData[i].value) === this.cdzForm.min
          }
          options = options.filter(item => item.value >= this.cdzForm.min)
        }
      } else if (this.cdzForm.options) {
        for (let i = 0; i < this.cdzForm.options.length; i++) {
          const selected = this.cdzForm.min && this.cdzForm.name === 'BBCH' ? this.strToNum(i) === this.cdzForm.min : false
          empty = this.thisValIsObject(this.cdzForm.options[i])
          if (!empty) {
            options[i] = {
              value: i,
              label: this.cdzForm.options[i],
              key: i,
              selected: selected,
              disabled: this.cdzForm.options[i].disabled ?? false
            }
          }
          if (selected) {
            options = options.filter(item => item.value >= this.cdzForm.min)
          }
        }
      }
      return options
    },
    splitMulti (str, tokens) {
      const tempChar = tokens[0]
      for (let i = 1; i < tokens.length; i++) {
        str = str.split(tokens[i]).join(tempChar)
      }
      str = str.split(tempChar)
      return str
    },
    delete (array, index) {
      this.$delete(array, index)
    }
  }
}
</script>
<style>
.custom-select-group {
  margin-left: 24px;
  margin-right: 15px;
  min-width: 90% !important;
  left: 0 !important;
}
</style>
