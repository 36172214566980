<template>
  <section style="width: 100vw;">
    <el-divider> Legende</el-divider>
    <div style="display: inline-grid">
      <div style="display: inline-flex">
        <div class="dot"></div>
        <div style="padding-left: 5px;"> - Optimales Spritzwetter</div>
      </div>
      <div style="display: inline-flex; padding-top: 10px;">
        <div class="dot" style="background-color: grey"></div>
        <div style="padding-left: 5px;"> - Nicht empfohlenes Spritzwetter</div>
      </div>
    </div>
    <el-divider>Prognosen</el-divider>
    <div
      class="chart-container" style="position: relative; height:135px; width:95vw"
      v-for="(item, key) in spritzWetterData"
      :key="key">
      <canvas :id="'spritzbedingungen-' + item.Datum.replaceAll('.', '_')"></canvas>
    </div>
  </section>
</template>

<script>

import { Chart } from 'chart.js'
import moment from 'moment'

export default {
  props: {
    spritzwetterData: Array,
    aktuellesDatum: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      hours: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
      spritzBedingungenChart: {},
      formatedData: null,
      spritzWetterData: null
    }
  },
  watch: {
    spritzwetterData: {
      immediate: true,
      handler: function (data) {
        if (data) {
          this.buildCharts(data)
        }
      }
    },
    formatedData: {
      immediate: true,
      handler: function (data) {
        if (data) {
          this.formatedData = data
          Array.from(data).forEach(res => {
            res.Datum = moment(res.Datum, moment.ISO_8601).format('DD.MM.YYYY')
          })
        }
      }
    }
  },
  methods: {
    buildCharts (data) {
      const self = this
      const spritzBedingungen = []
      const spritzDatum = []
      const spritzBedingungenChart = []
      self.spritzWetterData = data
      Array.from(self.spritzwetterData).forEach((item, key) => {
        spritzDatum.push(item.Datum)
        spritzBedingungenChart.push({
          [item.Datum.replaceAll('.', '-')]: null
        })
      })
      Array.from(self.hours).forEach((hour) => {
        spritzBedingungen.push({
          x: hour,
          y: 1
        })
      })
      self.spritzBedingungenChart = spritzBedingungenChart
      try {
        self.$nextTick(function () {
          Array.from(spritzDatum).forEach((date, key) => {
            const selector = 'spritzbedingungen-' + date.replaceAll('.', '-')
            self.spritzBedingungenChart[key][selector] = null
            const ctx = document.getElementById(selector).getContext('2d')
            const showDate = moment(date).format('DD.MM.YYYY')
            if (ctx !== null) {
              self.spritzBedingungenChart[key] = new Chart(ctx, {
                type: 'bar',
                data: {
                  labels: self.hours,
                  datasets: [
                    {
                      label: date,
                      data: spritzBedingungen,
                      borderColor: data[key].backgroundColor,
                      backgroundColor: data[key].backgroundColor,
                      borderWidth: 1
                    }
                  ]
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  interaction: {
                    mode: 'index',
                    intersect: false
                  },
                  plugins: {
                    title: {
                      display: true,
                      text: showDate,
                      font: {
                        weight: 'bold',
                        size: '15px'
                      }
                    },
                    legend: {
                      display: false
                    },
                    tooltip: {
                      enabled: true,
                      callbacks: {
                        title: function (context) {
                          const dataset = context[0].dataset
                          const dataIndex = context[0].dataIndex
                          dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                            return currentValue
                          })
                          const currentColor = dataset.backgroundColor[dataIndex]
                          let recommendation = 'Keine Empfehlung vorhanden!'
                          if (currentColor === '#80a6df') {
                            recommendation = 'Optimales Spritzwetter!'
                          } else if (currentColor === 'grey') {
                            recommendation = 'Nicht empfohlenes Spritzwetter!'
                          } else {
                            return recommendation
                          }
                          return recommendation
                        },
                        label: function (context) {
                          const dataIndex = context.dataIndex
                          const dataset = context.dataset
                          dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                            return currentValue
                          })
                          const currentValue = dataset.data[dataIndex].x
                          return ' ' + currentValue + ':00 Uhr'
                        }
                      }
                    }
                  },
                  stacked: false,
                  scales: {
                    y: {
                      beginAtZero: true,
                      position: 'left',
                      display: false
                    },
                    x: {
                      ticks: {
                        callback: function (value, index, ticks) {
                          return value + ' Uhr'
                        }
                      }
                    }
                  }
                }
              })
            }
          })
          this.formatedData = data
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}

</script>

<style>

.dot {
  height: 25px;
  width: 25px;
  background-color: #80a6df;
  border-radius: 50%;
  display: inline-block;
}

</style>
