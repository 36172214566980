<template>
<section>
  <el-row v-for="wetterStation in wetter.wetterStationen" :key="wetterStation.ID_Wetterstation">
    <wetter-chart :wetter-daten="wetterStation.wetterDaten"></wetter-chart>
  </el-row>
</section>
</template>

<script>
import wetterApi from '../api/wetter'
import WetterChart from '../components/charts/wetter-chart'

export default {
  components: { WetterChart },
  data () {
    return {
      kulturen: [],
      wetter: {
        wetterStationen: {
          wetterDaten: []
        }
      },
      navigation: {
        0: 'Wetter',
        1: 'Prognosen',
        2: 'Rueckmeldung'
      }
    }
  },
  created () {
    this.initWetterStationen()
    this.$store.commit('changeRouteName', 'Betriebs - Wetter')
  },
  methods: {
    initWetterStationen () {
      const self = this
      const idBetrieb = JSON.parse(localStorage.user).ID_Betrieb
      const dacomStation = '0'
      wetterApi.methods.getWetterstationenVonBetrieb({
        ID_Betrieb: idBetrieb,
        DacomStation: dacomStation
      }).then(response => {
        self.wetter.wetterStationen = response.data.wetterStationen
        if (response.data.wetterStationen.length > 0) {
          this.initWetterDaten()
        }
      })
    },
    initWetterDaten () {
      const self = this
      let idWetterstation = ''
      for (let i = 0; i < this.wetter.wetterStationen.length; i++) {
        idWetterstation = this.wetter.wetterStationen[i].ID_Wetterstation
        wetterApi.methods.getWetterdaten({
          ID_Wetterstation: idWetterstation
        }).then(response => {
          self.$set(self.wetter.wetterStationen[i], 'wetterDaten', response.data.wetterDaten)
        })
      }
    }
  }
}
</script>
