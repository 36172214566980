<template>
  <el-row type="flex" justify="center">
    <el-col :xs="Number(24)" :md="Number(12)" :lg="Number(12)"
            :xl="Number(6)">
      <el-card v-if="kultur">
        <div slot="header">
          <span><b>{{ kultur.name }} - Parzellen </b></span>
        </div>
        <component
          :nav-items="navigation"
          :nav-start-row="navParzellen.navStartRow"
          :nav-end-row="navParzellen.navEndRow"
          :nav-icon-folder="navParzellen.navIconFolder"
          :nav-is-link="false"
          :modal-kultur="kultur"
          :modal-data="parzellenFiltered"
          :modal-select="navParzellen.formSelect"
          @handleModalSubmit="handleModalSelect"
          v-bind:is="'nav-menu'"/>
      </el-card>
      <el-card v-if="kultur">
        <div slot="header">
          <span><b>Parzellenübersicht</b></span>
        </div>
        <el-row>
          <el-col>
            <el-input v-model="nameFilter" size="small" placeholder="Parzelle suchen..."
                      @input="filterParzellen"></el-input>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row v-for="parzelle in parzellenFiltered" :key="parzelle.ID_Parzelle"
                style="padding: 5px;"
        >
          <router-link v-if="parzelle.color === 'green'"
                       :to="{ name: 'Parzelle', params: { parzelle: parzelle, kultur: kultur }}"
                       style="color: white; text-decoration: none;font-size: 18px;">
            <el-button
              type="success"
              size="medium"
              style="width: 100%; min-height: 4rem"
            >
              <b style="font-size: 18px;">{{ parzelle.Name }}</b>
            </el-button>
          </router-link>
          <router-link v-else-if="parzelle.color === 'orange'"
                       :to="{ name: 'Parzelle', params: { parzelle: parzelle, kultur: kultur }}"
                       style="color: white; text-decoration: none;font-size: 18px;">
            <el-button
              type="warning"
              size="medium"
              style="width: 100%; min-height: 4rem"
            >
              <b style="font-size: 18px;">{{ parzelle.Name }}</b>
            </el-button>
          </router-link>
          <router-link v-else-if="parzelle.color === 'red'"
                       :to="{ name: 'Parzelle', params: { parzelle: parzelle, kultur: kultur }}"
                       style="color: white; text-decoration: none;">
            <el-button
              type="danger"
              size="medium"
              style="width: 100%; min-height: 4rem;"
            >
              <b style="font-size: 18px;">{{ parzelle.Name }}</b>
            </el-button>
          </router-link>
        </el-row>
        <el-row v-if="parzellenFiltered.length === 0">
          <el-col>
            <p><b> Keine Parzellen zu dieser Kultur vorhanden!</b></p>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import parzelle from '../api/parzelle'
import NavMenu from '../components/nav/nav-menu'
import { formMethods } from '../mixins/form-methods'

export default {
  components: {
    'nav-menu': NavMenu
  },
  mixins: [formMethods],
  props: {
    kultur: [Array, Object],
    navigation: [Array, Object]
  },
  watch: {
    $props: {
      immediate: true,
      handler: function (data) {
        data = {
          kid: data.idKultur,
          pid: data.idParzelle,
          pfid: data.idParzelleFruchtfolge,
          ft: data.formTree,
          fb: data.formBranch
        }
        this.$emit('updateGlobalData', data)
      }
    }
  },
  data () {
    return {
      deep: false,
      parzellen: [],
      parzellenFiltered: [],
      parzellenArray: [],
      nameFilter: '',
      value: [],
      navParzellen: {
        navStartRow: 1,
        navEndRow: 2,
        navIconFolder: 'navigation',
        formSelect: {
          multi: 2
        }
      },
      isCollapse: true
    }
  },
  created () {
    if (!this.kultur) {
      this.$router.replace(this.$route.query.redirect || '/')
    } else {
      this.$store.commit('changeRouteName', this.kultur.name + ' - Parzellen')
      this.initParzellen()
    }
    this.$store.commit('setKultur', this.kultur)
    this.$store.commit('setNavigation', this.$store.state.navigation)
    this.$store.commit('setDeepStateNavigation', false)
  },
  methods: {
    filterParzellen () {
      if (this.nameFilter !== '') {
        this.parzellenFiltered = this.parzellenFiltered.filter(item => item.Name.toLowerCase().includes(this.nameFilter.toLowerCase()) && item.Fruchtfolge.length > 0 && this.strToNum(item.Fruchtfolge[0].ID_Kultur) === this.kultur.id)
      } else {
        this.parzellenFiltered = this.parzellen.filter(item => item.Fruchtfolge.length > 0 && this.strToNum(item.Fruchtfolge[0].ID_Kultur) === this.kultur.id)
        this.parzellenArray = this.parzellenArray.filter(item => item.options.length > 0)
      }
    },
    initParzellen () {
      const self = this
      const idBetrieb = JSON.parse(localStorage.user).ID_Betrieb
      parzelle.methods.getUserParzellen({
        ID_Betrieb: idBetrieb,
        ID_Kultur: this.kultur.id
      }).then(response => {
        console.log('Parzellen', response.data.parzellen)
        self.parzellen = response.data.parzellen
        self.parzellenArray = response.data.parzellenArray
        self.filterParzellen()
      })
    },
    goBack () {
    }
  }
}
</script>
