<template>
  <section>
    <el-button
      class="nav-items"
      @click="centerDialogVisible = true"
      round>
      <div style="float: left">
        <el-image
          style="max-width: 50px; width: auto; height: 50px"
          :src="require('../../assets/'+modalNavIconFolder+'/'+modalNavIconName.replace(' ', '')+'.png')">
        </el-image>
      </div>
      <div style="color: white"><h2>{{ modalNavIconName }}</h2></div>
    </el-button>
    <el-dialog
      :title="modalNavIconName"
      :visible.sync="centerDialogVisible"
      width="90%"
      center>
      <el-row type="block">
        <el-col :span="24" style="margin-bottom: 20px">
          <el-alert
            type="info"
            show-icon
            :closable="false">
            <div slot="title">Parzellenauswahl</div>
            <div v-if="!modalSelect" slot="default"> Eine Parzelle auswählen und mit "Weiter" bestätigen.</div>
            <div v-if="modalSelect" slot="default"> Eine oder mehrere Parzellen auswählen und mit "Weiter" bestätigen.
            </div>
          </el-alert>
        </el-col>
        <el-col :span="24">
          <div class="el-row--flex">
            <el-select
              v-model="selectedParzellen"
              :multiple="modalSelect"
              size="medium"
              style="width: 75%;"
              placeholder="Bitte wählen">
              <el-option
                v-for="parzelle in modalData"
                :key="parzelle.ID_Parzelle"
                :label="parzelle.Name"
                :value="parzelle.ID_Parzelle">
                <span style="float: left">{{ parzelle.Name }}</span>
                <span style="float: right; padding-right: 20px; line-height: 2;">
              <el-button v-bind:style="{backgroundColor: parzelle.color }" circle></el-button>
            </span>
              </el-option>
            </el-select>
            <el-button type="primary" size="medium" style="margin-left: 10px; height: 36px; align-self: center"
                       @click="handleModalSubmit({ selectedParzellen: selectedParzellen, idKultur: parseInt(modalKultur.id), nav: modalNavIconName, parzellen: modalData})">Weiter</el-button>
          </div>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">Abbrechen</el-button>
  </span>
    </el-dialog>
  </section>
</template>

<script>

import { formMethods } from '../../mixins/form-methods'

export default {
  mixins: [formMethods],
  props: {
    showModal: Boolean,
    modalNavIconFolder: String,
    modalNavIconName: String,
    modalData: Array,
    modalKultur: Object,
    modalSelect: Boolean
  },
  data () {
    return {
      modalForm: {},
      dialogFormVisible: true,
      formLabelWidth: '100px',
      centerDialogVisible: false,
      selectedParzellen: []
    }
  },
  methods: {}
}
</script>
