<template>
  <el-input
    v-model="text"
    type="textarea"
    :autosize="{ minRows: 2, maxRows: 4}"
    :placeholder="cdzForm.value"
    @input="submitChange">
  </el-input>
</template>

<script>
export default {
  name: 'FormInputTextareaField',
  data () {
    return {
      text: ''
    }
  },
  watch: {
    cdzForm: {
      immediate: true,
      handler: function () {
        this.submitChange('')
      }
    }
  },
  methods: {
    submitChange (value) {
      const id = this.cdzForm.id
      const rmaId = this.cdzForm.rmaId
      const key = this.cdzForm.bundleId
      this.$emit('setNewFormData', {
        id: id,
        pId: id,
        rmaId: rmaId,
        key: key,
        value: value,
        date: this.date,
        formTree: this.formTree,
        formBranch: this.formBranch
      })
    }
  }
}
</script>
