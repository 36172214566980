<template>
  <section>
    <el-row
      v-for="(nav, i) in navItems"
      :key="i"
      type="block"
      class="row-bg">
      <div v-if="i >= navStartRow && i <= navEndRow" class="grid-content">
        <div v-if="navIsLink">
          <el-col :span="24">
            <router-link :to="{ name: nav, params: { kulturen: kulturen, parzellen: parzellen }}">
              <el-button class="nav-items" round>
                <div style="float: left">
                  <el-image v-if="require('../../assets/'+navIconFolder+'/'+nav.replace(' ', '')+'.png')"
                    style="max-width: 50px; height: 50px"
                    :src="require('../../assets/'+navIconFolder+'/'+nav.replace(' ', '')+'.png')">
                  </el-image>
                </div>
                <div style="color: white"><h2>{{ nav }}</h2></div>
              </el-button>
            </router-link>
          </el-col>
        </div>
        <div v-else>
          <el-col :span="24">
            <component
              :modal-nav-icon-folder="navIconFolder"
              :modal-nav-icon-name="nav"
              :modal-data="modalData"
              :modal-kultur="modalKultur"
              :modal-select="modalSelect.multi === parseInt(i)"
              :show-modal="modalVisible"
              @handleModalSubmit="handleModalSubmit"
              v-bind:is="'modal-base'"/>
          </el-col>
        </div>
      </div>
    </el-row>
  </section>
</template>

<script>
import ModalBase from '../modal/modal-base'
import { formMethods } from '../../mixins/form-methods'
export default {
  components: {
    'modal-base': ModalBase
  },
  mixins: [formMethods],
  props: {
    kulturen: {},
    parzellen: {},
    navItems: [Array, Object],
    navIconFolder: String,
    navStartRow: Number,
    navEndRow: Number,
    navIsLink: Boolean,
    modalKultur: Object,
    modalParzellen: Array,
    modalData: Array,
    modalSelect: {
      type: Object,
      default () {
        return {
          multi: Number
        }
      }
    }
  },
  data () {
    return {
      modalVisible: false,
      multi: false
    }
  }
}
</script>

<style>
.nav-items {
  width: 100%;
  margin-bottom: 10px;
  background-color: lightskyblue;
}
.nav-items:hover {
  background-color: lightskyblue;
}
</style>
