<template>
  <section>
    <el-collapse
      v-model="prognosen" accordion>
      <el-collapse-item
        v-if="ID_Kultur === '3'"
        title="Wetterdaten"
        name="wetterdaten">
        <template slot="title">
          <el-row type="flex" justify="space-between" style="width: 100%; align-self: baseline">
            <el-col :span="24"><b>Wetterdaten</b></el-col>
          </el-row>
        </template>
        <div class="chart-container" style="position: relative; height:50vh; width:100%">
          <canvas id="wetterDatenChart"></canvas>
        </div>
      </el-collapse-item>
      <el-collapse-item
        v-if="ID_Kultur === '3'"
        title="Blattnässe"
        name="blattnaesse">
        <template slot="title">
          <el-row type="flex" justify="space-between" style="width: 100%; align-self: baseline">
            <el-col :span="24"><b>Blattnässe</b></el-col>
          </el-row>
        </template>
        <div class="chart-container" style="position: relative; height:40vh; width:90vw">
          <canvas id="blattnaesseChart"></canvas>
        </div>
      </el-collapse-item>
      <el-collapse-item title="Infektionsrisiko" name="infektionsrisiko">
        <template slot="title">
          <el-row type="flex" justify="space-between" style="width: 100%; align-self: baseline">
            <el-col :span="24"><b>Infektionsrisiko</b></el-col>
          </el-row>
        </template>
        <div v-if="ID_Kultur === '3' && showGraph && infektionsrisikoData.data.length > 0" class="chart-container" style="position: relative; height:40vh; width:90vw">
          <infektionsrisiko-graph
            :infektionsrisiko="infektionsrisikoData.data"
            :title="infektionsrisikoData.title"
            :max-Height="50">
          </infektionsrisiko-graph>
        </div>
        <div
          v-if="empfehlungsDetailInfektionsrisikenLoaded && empfehlungsDetailInfektionsrisikenLoadedCount === empfehlungsDetailKrankheiten.length"
          class="chart-container" style="position: relative; height:auto; width:90vw">
          <infektionsrisiko-gantt
            :empfehlungs-detail-infektionsrisiken="empfehlungsDetailInfektionsrisiken"
            :empfehlungs-detail-restschutz="empfehlungsDetailRestschutz"
            :aktuelles-datum="aktuellesDatum"
            :kultur="currentRow.ID_Kultur"
            :pflanzenkrankheit="empfehlungsDetailKrankheit"
            :pflanzenkrankheiten="empfehlungsDetailKrankheiten"
            :sorte="parzelle.idSorte"
            @updateTable="updateTable"
          ></infektionsrisiko-gantt>
        </div>
        <div v-else>
          <el-card>Es stehen noch keine Daten zur Verfügung!</el-card>
        </div>
      </el-collapse-item>
      <el-collapse-item title="Spritzbedinungen" name="spritzbedingungen">
        <template slot="title">
          <el-row type="flex" justify="space-between" style="width: 100%; align-self: baseline">
            <el-col :span="24"><b>Spritzbedinungen</b></el-col>
          </el-row>
        </template>
        <div class="chart-container" style="position: relative; height:auto; width:90vw">
          <spritzbedingungen-gantt
            :spritzwetter-data="spritzwetterData"
            :aktuelles-datum="aktuellesDatum"
            :kultur="ID_Kultur"
          ></spritzbedingungen-gantt>
        </div>
      </el-collapse-item>
    </el-collapse>
  </section>
</template>

<script>
import infektionsrisiko from '../api/infektionsrisiko'
import krankheiten from '../api/krankheiten'
import sorte from '../api/sorte'
import algorithmus from '../api/algorithmus'
import wetter from '../api/wetter'
import moment from 'moment'
import axios from 'axios'
import { Chart, registerables } from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom'
import 'chartjs-adapter-moment'
import InfektionsrisikoGantt from '../components/charts/gantt/infektionsrisiko-gantt'
import InfektionsrisikoGraph from '../components/charts/infektionsrisiko-graph'
import SpritzbedingungenGantt from '../components/charts/gantt/spritzbedingungen-gantt'

moment.locale('de-DE')

Chart.register(...registerables, zoomPlugin)

export default {
  name: 'Prognosen',
  components: {
    InfektionsrisikoGraph,
    SpritzbedingungenGantt,
    InfektionsrisikoGantt
  },
  props: {
    parzelle: [Array, Object],
    kultur: [Array, Object],
    navigation: [Array, Object]
  },
  data () {
    return {
      id: null,
      prognosen: '',
      parzellenDaten: null,
      currentRow: null,
      empfehlungsDetailInfektionsrisikenLoaded: false,
      empfehlungsDetailInfektionsrisikenLoadedCount: 0,
      empfehlungsDetailInfektionsrisiken: {},
      empfehlungsDetailRestschutz: {},
      aktuellesDatum: moment().format('D.M'),
      empfehlungsDetailKrankheit: 0,
      sorte: null,
      empfehlungsDetailKrankheiten: [],
      ID_Wetterstation: null,
      infektionsrisikoChart: null,
      wetterChart: null,
      ID_Kultur: null,
      blattnaesseChart: null,
      spritzwetterData: null,
      spritzwetterGraphen: [],
      spritzwetterGraphen2: [],
      updated: false,
      deep: true,
      sorteMitPflanzenkrankheiten: null,
      allAlgorithmen: [],
      calculatedInfectionRisko: {},
      infektionsrisikoData: {
        data: [],
        title: ''
      },
      showGraph: false,
      phaseData: [],
      riskTable: [],
      yellowShell: {
        state: false,
        date: null
      }
    }
  },
  created () {
    if (!this.parzelle) {
      this.$router.replace(this.$route.query.redirect || '/')
    } else {
      this.initInfektionsrisikoDaten()
    }
    this.$store.commit('changeRouteName', 'Prognosen')
    this.$store.commit('setDeepStateNavigation', true)
  },
  watch: {
    parzelle: {
      immediate: true,
      handler: function (parzelle) {
        if (parzelle) {
          this.ID_Kultur = parzelle.idKultur
          this.id = parzelle.idParzelle
          this.sorte = parzelle.sorte
          this.prognosen = parzelle.idKultur === '3' ? 'wetterdaten' : this.updated ? 'infektionsrisiko' : ''
        }
      }
    },
    prognosen: {
      immediate: true,
      handler: function (prognose) {
        if (prognose === 'infektionsrisiko') {
          this.prognosen = this.updated ? 'infektionsrisiko' : ''
        }
      }
    },
    infektionsrisikoData: {
      deep: true,
      handler: function (infektionsrisikoData) {
        console.log('infektionsrisikoData', infektionsrisikoData.data.length)
        if (infektionsrisikoData.data.length > 0) {
          this.showGraph = true
        }
      }
    }
  },
  methods: {
    updateTable (table) {
      if (table) {
        table.doLayout()
        this.updated = true
        this.prognosen = this.parzelle.idKultur === '3' ? 'wetterdaten' : this.updated ? 'infektionsrisiko' : ''
      }
    },
    removeDataFromChart (chart) {
      if (chart) {
        chart.destroy()
      }
    },
    initInfektionsrisikoDaten () {
      const self = this
      infektionsrisiko.methods.getInfektionsRisikoDaten({
        ID_Parzelle: this.id
      }).then(response => {
        self.parzellenDaten = response.data.parzelle[0]
        self.handleSomething(response.data.parzelle[0])
      })
    },
    getAlleKrankheiten (kulturen, betriebe, labor, sorte, show = null, pflanzenkrankheiten = null) {
      let alleKrankheiten = {}
      krankheiten.methods.getAlleKrankheiten({
        kulturen: kulturen,
        betriebe: betriebe,
        labor: labor,
        sorte: sorte,
        show: show,
        krankheiten: pflanzenkrankheiten
      }).then(response => {
        alleKrankheiten = response
      })
      return alleKrankheiten
    },
    getSortenPflanzenkrankheiten (sorteId) {
      sorte.methods.getSortenPflanzenkrankheiten({
        ID_Sorte: sorteId
      }).then(response => {
        this.sorteMitPflanzenkrankheiten = response.data.data
      })
    },
    getPhasen (algoResult) {
      const phasen = []
      if (algoResult) {
        for (let i = 0; i < algoResult.length; i++) {
          for (let j = 0; j < algoResult[i].length; j++) {
            for (let k = 0; k < algoResult[i][j].length; k++) {
              if (algoResult[i][j][k].label === 'Temperatur-Summe') {
                phasen.push(algoResult[i][j][k].phase)
              }
            }
          }
        }
      }
      return phasen
    },
    handleSomething (row) {
      const self = this
      self.currentRow = {
        ID_Betrieb: row.ID_Betrieb,
        Parzelle: row.Name,
        Betrieb: row.Firma,
        KulturName: row.KulturName,
        SorteName: row.SorteName,
        LastLaborRueckmeldung: row.LastLaborRueckmeldung,
        Status: row.Status,
        Datum_Empfehlung: row.Datum_Empfehlung,
        Datum_Erinnerung: row.Datum_Erinnerung,
        Empfehlungstext: row.Empfehlungstext,
        Firma: row.Firma,
        LastEmpfehlung: row.LastEmpfehlung,
        LastRueckmeldung: row.LastRueckmeldung,
        ID_Wetterstation: 0,
        ID_Parzelle: row.ID_Parzelle,
        ID_Kultur: this.ID_Kultur,
        ID_Parzelle_Fruchtfolge: row.ID_Parzelle_Fruchtfolge,
        ID_Sorte: self.sorte
      }
      const datumVonString = moment().subtract(1, 'days').format('DD.MM.YYYY')
      const datumBisString = moment().add(10, 'days').format('DD.MM.YYYY')
      self.getSortenPflanzenkrankheiten(self.parzellenDaten.Fruchtfolge[0].ID_Sorte)
      wetter.methods.getWetterstationFromParzelle({
        ID_Parzelle: self.currentRow.ID_Parzelle
      }).then(response => {
        response = response.data
        if (response.error === 0) {
          self.currentRow.ID_Wetterstation = response.data[0].ID_Wetterstation
          this.ID_Wetterstation = self.currentRow.ID_Wetterstation
          krankheiten.methods.getAlleKrankheiten({
            kulturen: [self.currentRow.ID_Kultur],
            betriebe: [self.currentRow.ID_Betrieb],
            labor: false,
            sorte: [self.currentRow.ID_Sorte],
            show: true,
            krankheiten: self.sorteMitPflanzenkrankheiten
          }).then(response => {
            self.empfehlungsDetailInfektionsrisikenLoaded = false
            self.empfehlungsDetailInfektionsrisikenLoadedCount = 0
            response = response.data
            if (response.error === 0) {
              self.empfehlungsDetailKrankheiten = response.data
              self.empfehlungsDetailInfektionsrisiken = {}
              self.empfehlungsDetailRestschutz = {}
              const krankheiten = response.data
              Array.from(krankheiten).forEach((item, index) => {
                if (item.Show_In_Frontend === '1') {
                  algorithmus.methods.getAlleAlgorithmen({
                    ID_Algorithmus: null,
                    ID_Pflanzenkrankheit: item.ID_Pflanzenkrankheiten,
                    ID_Wirkstoffe: null,
                    ID_Wirkstoffe_Not: null,
                    ID_Type: null,
                    File: null,
                    Pflanzenkrankheiten: null,
                    Pflanzenschutzmittel: null,
                    Wirkstoffe: null,
                    Algorithm_New_Active: '1'
                  }).then(algoResult => {
                    algoResult = algoResult.data
                    if (algoResult.data.length > 0) {
                      self.allAlgorithmen.push({
                        ID_Pflanzenkrankheit: item.ID_Pflanzenkrankheiten,
                        ID_Algorithmus: algoResult.data[0].ID_Algorithmus
                      })
                      this.$set(item, 'phasen', self.getPhasen(algoResult.data[0].Algorithm_Object ?? false))
                      algorithmus.methods.calculateInfectionRisk({
                        onlyUpdate: 'false',
                        algorithmus: false,
                        ID_Wetterstation: self.currentRow.ID_Wetterstation,
                        dateFrom: datumVonString,
                        dateTo: datumBisString,
                        ID_Algorithmus: algoResult.data[0].ID_Algorithmus
                      }).then(Infektionsrisiko => {
                        const InfektionsrisikoData = Infektionsrisiko.data.data
                        Infektionsrisiko = Infektionsrisiko.data
                        self.phaseData = Infektionsrisiko.phase ?? []
                        self.riskTable = Infektionsrisiko.risk_table ?? []
                        if (index === 0) {
                          if (((self.currentRow.ID_Kultur === 6 && self.empfehlungsDetailKrankheit === 0) || (self.currentRow.ID_Kultur === 6 && self.empfehlungsDetailKrankheit === '3'))) {
                            self.empfehlungsDetailKrankheit = '3'
                          } else if (self.empfehlungsDetailKrankheit === 0) {
                            self.empfehlungsDetailKrankheit = item.ID_Pflanzenkrankheiten
                          }
                          const krankheitenName = krankheiten.find(itm => itm.ID_Pflanzenkrankheiten === self.empfehlungsDetailKrankheit).Name
                          self.createInfektionsrisikoGraph(InfektionsrisikoData, krankheitenName)
                        }
                        this.$set(self.calculatedInfectionRisko, [item.Anzeige_Name], Infektionsrisiko)
                        let phaseCheck = 0
                        for (let i = 0; i < InfektionsrisikoData.length; i++) {
                          phaseCheck = phaseCheck + InfektionsrisikoData[i].length
                        }
                        if (InfektionsrisikoData) {
                          InfektionsrisikoData.forEach((data, idx) => {
                            const startDate = moment().subtract(6, 'days')
                            const endDate = moment().add(12, 'days')
                            const diffDays = endDate.diff(startDate, 'days')
                            if (data.length === 0 && phaseCheck === 0) {
                              for (let i = 0; i < diffDays; i++) {
                                const date = moment(startDate).add(i, 'days').format('D.M')
                                if (!self.empfehlungsDetailInfektionsrisiken[item.Anzeige_Name]) {
                                  self.$set(self.empfehlungsDetailInfektionsrisiken, [item.Anzeige_Name], {})
                                  self.$set(self.empfehlungsDetailInfektionsrisiken[item.Anzeige_Name], date,
                                    {
                                      Pflanzenkrankheit: item.ID_Pflanzenkrankheiten,
                                      Risiko: 0,
                                      Color: '#D3D3D3'
                                    }
                                  )
                                }
                                if (!self.empfehlungsDetailInfektionsrisiken[item.Anzeige_Name][date]) {
                                  self.$set(self.empfehlungsDetailInfektionsrisiken[item.Anzeige_Name], date, {
                                    Pflanzenkrankheit: item.ID_Pflanzenkrankheiten,
                                    Risiko: 0,
                                    Color: '#D3D3D3'
                                  })
                                }
                                if (!self.empfehlungsDetailInfektionsrisiken[item.Anzeige_Name][moment(endDate).format('DD.MM')]) {
                                  self.$set(self.empfehlungsDetailInfektionsrisiken[item.Anzeige_Name], moment(endDate).format('DD.MM'), {
                                    Pflanzenkrankheit: item.ID_Pflanzenkrankheiten,
                                    Risiko: 0,
                                    Color: '#D3D3D3'
                                  })
                                }
                              }
                            } else {
                              const newDataArray = []
                              const fill = []
                              if (data.length > 0 && data.length < 19) {
                                for (let i = 0; i < 19; i++) {
                                  const date = moment(startDate).add(i, 'days')
                                  if (!data[i]) {
                                    fill.push(i)
                                    newDataArray.push({
                                      x: date.format('YYYY-MM-DD 0:00'),
                                      y: 0
                                    })
                                  } else {
                                    newDataArray.push({
                                      x: data[i].x,
                                      y: data[i].y
                                    })
                                  }
                                }
                                data = newDataArray
                              }
                              data.forEach((inf, idy) => {
                                const date = moment(inf.x).format('D.M')
                                console.log('date', date)
                                if (Infektionsrisiko.phase && Infektionsrisiko.phase[idx] && Infektionsrisiko.phase[idx][0].yellowShell.state === 'true') {
                                  this.yellowShell.state = Infektionsrisiko.phase[idx][0].yellowShell.state
                                  this.yellowShell.date = moment(Infektionsrisiko.phase[idx][0].yellowShell.date).format('DD.MM.YYYY')
                                }
                                const color = fill.length > 0 && fill.filter(f => f === idy).length > 0 ? '#D3D3D3' : Infektionsrisiko.phase.length > 0 ? Infektionsrisiko.phase[idx][0].color : ''
                                if (!self.empfehlungsDetailInfektionsrisiken[item.Anzeige_Name]) {
                                  self.$set(self.empfehlungsDetailInfektionsrisiken, [item.Anzeige_Name], {})
                                  self.$set(self.empfehlungsDetailInfektionsrisiken[item.Anzeige_Name], date,
                                    {
                                      Pflanzenkrankheit: item.ID_Pflanzenkrankheiten,
                                      Risiko: inf.y,
                                      Color: color
                                    }
                                  )
                                }
                                if (!self.empfehlungsDetailInfektionsrisiken[item.Anzeige_Name][date]) {
                                  self.$set(self.empfehlungsDetailInfektionsrisiken[item.Anzeige_Name], date, {
                                    Pflanzenkrankheit: item.ID_Pflanzenkrankheiten,
                                    Risiko: inf.y,
                                    Color: color
                                  })
                                }
                                if (inf.y > 0) {
                                  self.$set(self.empfehlungsDetailInfektionsrisiken[item.Anzeige_Name], date, {
                                    Pflanzenkrankheit: item.ID_Pflanzenkrankheiten,
                                    Risiko: inf.y,
                                    Color: color
                                  })
                                }
                              })
                            }
                          })
                          self.growthRegulatorTimes = self.riskTable
                          this.$set(self, 'empfehlungsDetailInfektionsrisikenLoadedCount', self.empfehlungsDetailInfektionsrisikenLoadedCount + 1)
                          self.empfehlungsDetailInfektionsrisikenLoaded = true
                        }
                      })
                    } else {
                      self.empfehlungsDetailKrankheiten.splice(self.empfehlungsDetailKrankheiten.indexOf(item), 1)
                      self.empfehlungsDetailInfektionsrisikenLoaded = false
                    }
                  })
                  infektionsrisiko.methods.getRestschutz({
                    ID_Parzelle: self.currentRow.ID_Parzelle,
                    ID_Pflanzenkrankheiten: item.ID_Pflanzenkrankheiten,
                    Datum_Von: datumVonString,
                    Datum_Bis: datumBisString
                  }).then(rs => {
                    if (rs.data.error === 0) {
                      rs.data.restschutz.forEach((res, idx) => {
                        const date = moment(res.Datum, moment.ISO_8601).format('D.M')
                        if (!self.empfehlungsDetailRestschutz[item.Anzeige_Name]) {
                          self.$set(self.empfehlungsDetailRestschutz, [item.Anzeige_Name], {})
                          self.$set(self.empfehlungsDetailRestschutz[item.Anzeige_Name], date, parseFloat(res.Restschutz))
                        }
                        if (!self.empfehlungsDetailRestschutz[item.Anzeige_Name][date]) {
                          self.$set(self.empfehlungsDetailRestschutz[item.Anzeige_Name], date, parseFloat(res.Restschutz))
                        }
                      })
                    }
                  })
                }
              })
            }
          })
          self.createWetterGraph(response.data[0].ID_Wetterstation, datumVonString, datumBisString)
          self.createSpritzWetter()
        }
      })
    },
    createSpritzWetter () {
      const self = this
      let spritzwetterTag = ''
      let backgroundColor = []
      const spritzwetterGraphen = []
      const spritzwetterGraphen2 = []

      wetter.methods.getSpritzWetter({
        ID: this.ID_Wetterstation,
        Tage: 9
      }).then(response => {
        self.spritzwetterData = response.data.spritzwetter
        Array.from(self.spritzwetterData).forEach((res, idx) => {
          spritzwetterTag = res.Datum
          backgroundColor = res.backgroundColor
          spritzwetterGraphen.push({
            grafik: 'spritzwetterGraph' + idx,
            grafik2: 'spritzwetterGraph2' + idx
          })

          this.spritzwetterGraphen = spritzwetterGraphen
          this.spritzwetterGraphen2 = spritzwetterGraphen2
          this.createSpritzwetterGraph(spritzwetterTag, backgroundColor, idx)
        })
      })
    },
    createSpritzwetterGraph (spritzwetterTag, backgroundColor, id) {
      const self = this
      let spritzwetterGraph = 'spritzwetterGraph' + id
      try {
        self.$nextTick(function () {
          // Zunächst alte Daten vom Graph löschen
          self.removeDataFromChart(self.spritzwetterGraph)
          const ctxContent = document.getElementById(spritzwetterGraph)
          if (ctxContent != null) {
            const ctx = document.getElementById(spritzwetterGraph).getContext('2d')
            spritzwetterGraph = new Chart(ctx, {
              type: 'pie',
              data: {
                datasets:
                  [
                    {
                      data: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
                      backgroundColor: backgroundColor,
                      label: ['0:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
                    }
                  ]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                  mode: 'index',
                  intersect: false
                },
                stacked: false,
                title: {
                  display: true,
                  text: moment(spritzwetterTag).format('DD.MM.YYYY')
                },
                hover: {
                  mode: 'nearest',
                  intersect: false,
                  onHover: function (e, item) {
                  }
                },
                legend: {
                  display: true
                },
                tooltips: {
                  enabled: true,
                  callbacks: {
                    title: function (tooltipItem, data) {
                      const dataIndex = tooltipItem[0].index
                      const dataset = data.datasets[0]
                      dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                        return currentValue
                      })
                      const currentColor = data.datasets[0].backgroundColor[dataIndex]
                      let recommendation = 'Keine Empfehlung vorhanden!'
                      if (currentColor === '#80a6df') {
                        recommendation = 'Optimales Spritzwetter!'
                      } else if (currentColor === 'grey') {
                        recommendation = 'Nicht empfohlenes Spritzwetter!'
                      } else {
                        return recommendation
                      }
                      return recommendation
                    },
                    label: function (tooltipItem, data) {
                      const dataIndex = tooltipItem.index
                      const dataset = data.datasets[0]
                      dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
                        return currentValue
                      })
                      const currentValue = data.datasets[0].label[dataIndex]
                      return ' ' + currentValue + ' Uhr'
                    }
                  }
                },
                elements: {
                  arc: {
                    borderWidth: 0,
                    weight: 0,
                    borderAlign: 'center',
                    borderColor: 'black'
                  }
                }
              }
            })
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getSpritzWetter (idWetterstation) {
      let spritzWetter = {}
      wetter.methods.getSpritzWetter({
        ID: idWetterstation,
        Tage: 9
      }).then(response => {
        spritzWetter = response
      })
      return spritzWetter
    },
    createInfektionsrisikoGraph (Infektionsrisiko, Krankheitsname) {
      const datasets = []
      if (Infektionsrisiko[0]) {
        const infektionsrisikoData = []
        Infektionsrisiko[0].forEach(item => {
          const datumsStringFormated = moment(item.x).format('YYYY-MM-DD HH:mm')
          infektionsrisikoData.push({
            x: datumsStringFormated,
            y: item.y
          })
        })
        datasets.push({
          label: 'Infektionsrisiko ' + Krankheitsname,
          data: infektionsrisikoData
        })
      }
      this.$nextTick(function () {
        this.infektionsrisikoData.data = datasets[0].data
        this.infektionsrisikoData.title = datasets[0].label
      })
    },
    createWetterGraph (idWetterstation, datumVonString, datumBisString) {
      const self = this
      wetter.methods.getWetterDatenFuerWetterstationVonBis({
        ID_Wetterstation: idWetterstation,
        Datum_Von: datumVonString,
        Datum_Bis: datumBisString
      }).then(response => {
        const weatherDataTemperature = []
        const weatherDataRain = []
        const weatherDataDewPoint = []
        const weatherDataBlattnaesse = []
        Array.from(response.data).forEach(item => {
          const datumQuery = item.Datum + ' ' + item.Stunde + ':00'
          const datum = moment(datumQuery, moment.ISO_8601).format()
          weatherDataRain.push({
            x: datum,
            y: item.Niederschlagsmenge
          })
          weatherDataTemperature.push({
            x: datum,
            y: item.Temperatur
          })
          weatherDataDewPoint.push({
            x: datum,
            y: item.Taupunkt
          })
          weatherDataBlattnaesse.push({
            x: datum,
            y: item.Blattnaesse
          })
        })
        // WetterGraph erzeugen
        try {
          self.$nextTick(function () {
            // Zunächst alte Daten vom Graph löschen
            self.removeDataFromChart(this.wetterChart)
            const ctxContent = document.getElementById('wetterDatenChart')
            if (ctxContent != null) {
              const ctx = document.getElementById('wetterDatenChart').getContext('2d')
              self.wetterChart = new Chart(ctx, {
                type: 'line',
                data: {
                  datasets: [
                    {
                      label: 'Temperatur',
                      data: weatherDataTemperature,
                      borderColor: 'red',
                      backgroundColor: 'red',
                      fill: false,
                      pointRadius: 0,
                      yAxisID: 'y',
                      tension: 0.5
                      // cubicInterpolationMode: 'default'
                    },
                    {
                      label: 'Niederschlag',
                      data: weatherDataRain,
                      borderColor: '#037ffc',
                      backgroundColor: '#037ffc',
                      fill: true,
                      pointRadius: 0,
                      yAxisID: 'y1',
                      tension: 0.5
                    },
                    {
                      label: 'Taupunkt',
                      data: weatherDataDewPoint,
                      borderColor: 'lightblue',
                      backgroundColor: 'lightblue',
                      fill: false,
                      pointRadius: 0,
                      yAxisID: 'y',
                      tension: 0.5
                    }
                  ]
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  interaction: {
                    mode: 'index',
                    intersect: false
                  },
                  stacked: false,
                  plugins: {
                    title: {
                      display: false,
                      text: 'Wetterdaten'
                    }
                  },
                  scales: {
                    x: {
                      type: 'time',
                      display: true,
                      title: {
                        display: true,
                        text: 'Datum'
                      },
                      time: {
                        unit: 'day'
                      },
                      grid: {
                        display: false
                      }
                    },
                    y: {
                      type: 'linear',
                      display: true,
                      title: {
                        display: true,
                        text: '°C'
                      },
                      position: 'left'
                    },
                    y1: {
                      type: 'linear',
                      display: true,
                      title: {
                        display: true,
                        text: 'mm'
                      },
                      max: 1.4,
                      position: 'right',
                      grid: {
                        drawOnChartArea: false
                      }
                    }
                  }
                }
              })
              self.wetterChart.scales.x.ticks[0].label = 'Heute'
              self.wetterChart.update()
            }
          })
        } catch (e) {
          console.log(e)
        }
        // BlattnässeGraph erzeugen
        try {
          self.$nextTick(function () {
            // Zunächst alte Daten vom Graph löschen
            // self.removeDataFromChart(this.blattnaesseChart)
            const ctxContent = document.getElementById('blattnaesseChart')
            if (ctxContent != null) {
              const ctx = document.getElementById('blattnaesseChart').getContext('2d')
              self.blattnaesseChart = new Chart(ctx, {
                type: 'line',
                data: {
                  datasets: [
                    {
                      label: 'Blattnässe',
                      data: weatherDataBlattnaesse,
                      yAxis: 'y',
                      borderColor: '#53a113',
                      backgroundColor: '#53a113',
                      type: 'line',
                      fill: true,
                      pointRadius: 0
                    }
                  ]
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  interaction: {
                    mode: 'index',
                    intersect: false
                  },
                  stacked: false,
                  plugins: {
                    title: {
                      display: false,
                      text: 'Blattnässe'
                    }
                  },
                  scales: {
                    x: {
                      type: 'time',
                      display: true,
                      title: {
                        display: true,
                        text: 'Datum'
                      },
                      time: {
                        unit: 'day'
                      }
                    },
                    y: {
                      type: 'linear',
                      display: true,
                      title: {
                        display: true,
                        text: 'Blattnässes'
                      },
                      min: 0,
                      max: 1,
                      ticks: {
                        stepSize: 50
                      },
                      position: 'left'
                    },
                    y1: {
                      type: 'linear',
                      display: true,
                      position: 'right',
                      min: 0,
                      max: 1,
                      ticks: {
                        stepSize: 50
                      }
                    }
                  }
                }
              })
            }
          })
        } catch (e) {
          console.log(e)
        }
      })
    },
    getWetterstationFromParzelle (idParzelle) {
      let wetterStation = {}
      wetter.methods.getWetterstationFromParzelle({
        ID_Parzelle: idParzelle
      }).then(response => {
        wetterStation = response
      })
      return wetterStation
    },
    getWetterdaten (WetterdatenQuery) {
      return axios.post(axios.defaults.baseURL + 'templates/wetterdaten/getWetterdaten', WetterdatenQuery)
    }
  }
}
</script>

<style>
.el-main.main-content {
  padding-left: 0;
  padding-right: 0;
}

.el-card__body {
  padding: 20px;
}

.dot-round-green {
  height: 25px;
  width: 25px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.dot-round-red {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}

.dot-square-blue {
  height: 25px;
  width: 25px;
  background-color: blue;
  border-radius: 50%;
  display: inline-block;
}

.dot-square-orange {
  height: 25px;
  width: 25px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
}

.no-padding {
  padding: 0;
}
</style>
