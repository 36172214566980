import { encode } from 'querystring-es3'
const axios = require('axios')

export default {
  methods: {
    getAlleAlgorithmen (query) {
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/Algorithmus/getAlleAlgorithmen/pwaCallAlgorithmus', query)
    },
    calculateInfectionRisk (query) {
      console.log('calculateInfectionRisk', query)
      query = encode({ query: window.btoa(JSON.stringify(query)) })
      return axios.post(axios.defaults.baseURL + 'api/Algorithmus/calculateInfectionRisk/pwaCallRisk', query)
    }
  }
}
