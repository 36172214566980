import { render, staticRenderFns } from "./kultur-rueckmeldung.vue?vue&type=template&id=6e7a390f&scoped=true&"
import script from "./kultur-rueckmeldung.vue?vue&type=script&lang=js&"
export * from "./kultur-rueckmeldung.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e7a390f",
  null
  
)

export default component.exports